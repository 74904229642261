import {Button, Checkbox, Label, Modal, TextInput} from "flowbite-react";
import {useContext, useState} from "react";
import {GlobalContext} from "../../Context/GlobalContext";

export const ModalAddPhotoPortfolio = ({openModal, onCloseModal, handleAddPhoto}) => {
    const {functionContext} = useContext(GlobalContext);
    const {uploadPhotoApi} = functionContext;
    const [selectedFile, setSelectedFile] = useState(null);
    
    const handleChangePhoto = (e) => {
        setSelectedFile(e.target.files[0]);
    }
    
    const handleAdd = () => {
        if (!selectedFile) {
            alert('No photo found');
        }
        const formData = new FormData();
        formData.append("image", selectedFile);
        uploadPhotoApi(formData, (data) => {
            handleAddPhoto(data)
        })
    }
    
    return(
        <Modal show={openModal} size="md" onClose={onCloseModal} popup>
            <Modal.Header />
            <Modal.Body>
                <div className="space-y-6">
                    <h3 className="text-xl text-center font-medium text-gray-900 dark:text-white">Add Photo</h3>
                    <input 
                        onChange={handleChangePhoto}
                        type="file" 
                        className="file-input file-input-bordered file-input-primary w-full max-w-xs" />
                    <div className="w-full">
                        <Button onClick={handleAdd}>Upload</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}