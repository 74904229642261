import {BrowserRouter, Route, Routes} from "react-router-dom";
import React from "react";
import {Home} from "../pages/Home";
import Second from "../pages/Second";
import AuthRoute from "./AuthRoute";
import PublicRoute from "./PublicRoute";
import Login from "../pages/Login";
import LandingPage from "../pages/LandingPage";
import PortfolioList from "../pages/PortfolioList";
import PortfolioDetail from "../pages/PortfolioDetail";
import HomeAdmin from "../pages/HomeAdmin";
import Profile from "../pages/Admin/Profile";
import {ProfileForm} from "../pages/Admin/ProfileForm";
import {Account} from "../pages/Admin/Account";
import {ChangePassword} from "../pages/Admin/ChangePassword";
import {PortfolioAdmin} from "../pages/Admin/PortfolioAdmin";
import {EducationAdmin} from "../pages/Admin/EducationAdmin";
import {PortfolioForm} from "../pages/Admin/PortfolioForm";
import {EducationForm} from "../pages/Admin/EducationForm";
import {ExperienceAdmin} from "../pages/Admin/ExperienceAdmin";
import {ExperienceForm} from "../pages/Admin/ExperienceForm";
import {MasterTech} from "../pages/Admin/MasterTech";
import {SocialMediaAdmin} from "../pages/Admin/SocialMediaAdmin";
import {TechStackAdmin} from "../pages/Admin/TechStackAdmin";
import Inbox from "../pages/Admin/Inbox";
import {InboxDetail} from "../pages/Admin/InboxDetail";
import {GlobalProvider} from "../Context/GlobalContext";

const RouterApp = () => {
    return(
        <BrowserRouter>
            <React.Fragment>
                <GlobalProvider>
                    <Routes>
                        <Route
                            path={'/'}
                            element={<>
                                <LandingPage />
                            </>}
                        />
                        <Route
                            path={'/login'}
                            element={<PublicRoute>
                                <Login />
                            </PublicRoute>}
                        />
                        <Route
                            path={'/second'}
                            element={<AuthRoute>
                                <Second />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/portfolios'}
                            element={<>
                                <PortfolioList />
                            </>}
                        />
                        <Route
                            path={'/portfolio/:id'}
                            element={<>
                                <PortfolioDetail />
                            </>}
                        />

                        {/* Admin */}
                        <Route
                            path={'/admin'}
                            element={<AuthRoute>
                                <HomeAdmin />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/admin/profile'}
                            element={<AuthRoute>
                                <Profile />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/admin/profile/edit'}
                            element={<AuthRoute>
                                <ProfileForm />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/admin/account'}
                            element={<AuthRoute>
                                <Account />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/admin/change-password'}
                            element={<AuthRoute>
                                <ChangePassword />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/admin/portfolio'}
                            element={<AuthRoute>
                                <PortfolioAdmin />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/admin/education'}
                            element={<AuthRoute>
                                <EducationAdmin />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/admin/education/:id'}
                            element={<AuthRoute>
                                <EducationForm />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/admin/education/add'}
                            element={<AuthRoute>
                                <EducationForm />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/admin/portfolio/:id'}
                            element={<AuthRoute>
                                <PortfolioForm />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/admin/portfolio/add'}
                            element={<AuthRoute>
                                <PortfolioForm />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/admin/experience'}
                            element={<AuthRoute>
                                <ExperienceAdmin />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/admin/experience/add'}
                            element={<AuthRoute>
                                <ExperienceForm />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/admin/experience/:id'}
                            element={<AuthRoute>
                                <ExperienceForm />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/admin/masterTech'}
                            element={<AuthRoute>
                                <MasterTech />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/admin/socialMedia'}
                            element={<AuthRoute>
                                <SocialMediaAdmin />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/admin/techStack'}
                            element={<AuthRoute>
                                <TechStackAdmin />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/admin/inbox'}
                            element={<AuthRoute>
                                <Inbox />
                            </AuthRoute>}
                        />
                        <Route
                            path={'/admin/inbox/detail/:id'}
                            element={<AuthRoute>
                                <InboxDetail />
                            </AuthRoute>}
                        />
                    </Routes>
                </GlobalProvider>
            </React.Fragment>
        </BrowserRouter>
    )
}

export default RouterApp;