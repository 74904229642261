import {HeaderAdmin} from "../HeaderAdmin";
import {EducationFormAdminService} from "../../../pages/Services/EducationFormAdminService";
import SpinnerLoading from "../../loadings/SpinnerLoading";
import {useEffect} from "react";
import {Link} from "react-router-dom";

const linkList = [
    {
        name: "Home",
        linkUrl: "/admin"
    },
    {
        name: "Education",
        linkUrl: "/admin/education"
    },
    {
        name: "Form",
        linkUrl: "/"
    }
]

export const EducationFormComponent = () => {
    // const navigate = useNavigate();
    const {stateService, functionService} = EducationFormAdminService();
    const {educationForm, setEducationForm, title, isLoading, setIsLoading, errorMessage} = stateService;
    const { submit, handleChangeInputEducation, fetchEducationDetail } = functionService;
    
    useEffect(() => {
        fetchEducationDetail();
    }, [])
    
    return(
        <>
            {isLoading && (
                <SpinnerLoading text={'Saving data'} />
            )}
            <form onSubmit={submit}>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                        <HeaderAdmin 
                            title={title} 
                            linkList={linkList}
                            subTitle={'This information will be displayed publicly so be careful what you share.'} />

                        {/*{errorMessage && (*/}
                        {/*    <Alert color="failure">*/}
                        {/*        <span className="font-medium">Info alert!</span> {errorMessage}*/}
                        {/*    </Alert>*/}
                        {/*)}*/}
                        
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-2 sm:col-start-1">
                                <label htmlFor="subject1" className="block text-sm font-medium leading-6 text-gray-900">
                                    Subject
                                </label>
                                <div className="mt-2">
                                    <input
                                        onChange={handleChangeInputEducation}
                                        value={educationForm.subject}
                                        type="text"
                                        name="subject"
                                        id="subject1"
                                        required
                                        autoComplete="address-level2"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="institution1" className="block text-sm font-medium leading-6 text-gray-900">
                                    Institution
                                </label>
                                <div className="mt-2">
                                    <input required
                                        onChange={handleChangeInputEducation}
                                        value={educationForm.institution}
                                        type="text"
                                        name="institution"
                                        id="institution1"
                                        autoComplete="address-level1"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2 sm:col-start-1">
                                <label htmlFor="start1" className="block text-sm font-medium leading-6 text-gray-900">
                                    Start
                                </label>
                                <div className="mt-2">
                                    <input required
                                        onChange={handleChangeInputEducation}
                                        value={educationForm.start}
                                        type="text"
                                        name="start"
                                        id="start1"
                                        autoComplete="address-level2"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="end1" className="block text-sm font-medium leading-6 text-gray-900">
                                    End
                                </label>
                                <div className="mt-2">
                                    <input required
                                        onChange={handleChangeInputEducation}
                                        value={educationForm.end}
                                        type="text"
                                        name="end"
                                        id="end1"
                                        autoComplete="address-level1"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-4">
                                <label htmlFor="region1" className="block text-sm font-medium leading-6 text-gray-900">
                                    Type
                                </label>
                                <div className="mt-2 flex gap-5">
                                    <div>
                                        <input type="radio" value={true} checked={educationForm.isFormal === true} onChange={handleChangeInputEducation} name="isFormal" className="radio radio-primary" /> Formal
                                    </div>
                                    <div>
                                        <input type="radio" value={false} checked={educationForm.isFormal === false} onChange={handleChangeInputEducation} name="isFormal" className="radio radio-primary" /> Non-Formal
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-full">
                                <label htmlFor="description1" className="block text-sm font-medium leading-6 text-gray-900">
                                    Description
                                </label>
                                <div className="mt-2">
                                <textarea
                                    id="description1"
                                    name="description"
                                    onChange={handleChangeInputEducation}
                                    rows={3}
                                    required 
                                    value={educationForm.description}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    defaultValue={''}
                                />
                                </div>
                            </div>
                            
                        </div>

                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-3">
                    <Link to={'/admin/education'} className="btn text-sm font-semibold leading-6 text-gray-900 bg-white btn btn-sm">
                        Cancel
                    </Link>
                    <button
                        type="submit"
                        className="btn-sm btn bg-blue-600 hover:bg-blue-800 text-white"
                    >
                        Save
                    </button>
                </div>
            </form>
        </>
    )
}