import NavbarLanding from "../components/NavbarLanding";
import FooterComponent from "../components/landingPages/FooterComponent";
import React from "react";

const LandingPageWidget = ({children}) => {
    return(
        <React.Fragment>
            <div className={'px-8 sticky top-0 w-full bg-white navbar-wrapper'}>
                <NavbarLanding />
            </div>
            <div className={'flex flex-col gap-14 content-wrapper'}>
                {children}
            </div>
            <FooterComponent />
            <style jsx>{`
        .navbar-wrapper {
          position: sticky;
          top: 0;
          z-index: 1000; /* Set z-index tinggi untuk navbar */
          background-color: white;
        }

        .content-wrapper {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 14px;
        }
      `}</style>
        </React.Fragment>
    )
}
export default LandingPageWidget;