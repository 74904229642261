import {AdminWidget} from "../../widget/AdminWidget";
import {InboxDetailComponent} from "../../components/admin/InboxDetail/InboxDetailComponent";

export const InboxDetail = () => {
    return(
        <AdminWidget>
            <div className={'w-full flex items-center justify-center'}>
                <div className={'w-full'}>
                    <InboxDetailComponent/>
                </div>
            </div>
        </AdminWidget>
    )
}