import {useParams} from "react-router-dom";
import HeaderPortfolioDetail from "../components/landingPages/PortfolioDetail/HeaderPortfolioDetail";
import LandingPageWidget from "../widget/LandingPageWidget";
import ProjectDescription from "../components/landingPages/PortfolioDetail/ProjectDescription";
import CarouselComponent from "../components/landingPages/PortfolioDetail/CarouselComponent";
import TechnologiesUsed from "../components/landingPages/PortfolioDetail/TechnologiesUsed";
import BreadcrumbsComponent from "../components/BreadcrumbsComponent";
import {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../Context/GlobalContext";
import SpinnerLoading from "../components/loadings/SpinnerLoading";

const linkList = [
    {
        name: "Home",
        linkUrl: "/"
    },
    {
        name: "Portfolio",
        linkUrl: "/portfolios"
    },
    {
        name: "Detail",
        linkUrl: "/"
    }
]
const PortfolioDetail = () => {
    const {id} = useParams();
    const {functionContext, stateContext} = useContext(GlobalContext);
    const {portfolioDetail} = stateContext;
    const {fetchPortfolioByIdApi} = functionContext;
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        setIsLoading(true);
        fetchPortfolioByIdApi(id, () => {});
        setIsLoading(false);
    },[])
    
    return(
        <LandingPageWidget>
            {(!portfolioDetail && !isLoading) && (
                <SpinnerLoading text={'Loading fetch data'} />
            )}
            {portfolioDetail && (
                <>
                    <HeaderPortfolioDetail text={portfolioDetail.title} />
                    <div className={'flex w-full justify-center items-center bg-slate-50'}>
                        <div className={'flex flex-col gap-5 w-[70%]'}>
                            <BreadcrumbsComponent linkList={linkList} />
                            <img src={portfolioDetail.photoUrl} alt={'background'} className={'w-full'}/>
                            <div className={'flex flex-col gap-3'}>
                                <ProjectDescription description={portfolioDetail.description} linkProject={portfolioDetail.link} />
                                <CarouselComponent photos={portfolioDetail.portfolioPhotos}/>
                                <TechnologiesUsed tags={portfolioDetail.portfolioTechs} />
                            </div>
                        </div>
                    </div>
                </>
            )}
            
        </LandingPageWidget>
    )
}
export default PortfolioDetail;