import ComponentTitle from "../ComponentTitle";
import TechCard from "./TechCard";
import javaIcon from "../../../resources/tech-stacks/java-original.svg";
import csharpIcon from "../../../resources/tech-stacks/c-sharp.png";
import skillIcon from "../../../resources/tech-stacks/skill.png";
import javascriptIcon from "../../../resources/tech-stacks/javascript-original.svg";
import phpIcon from "../../../resources/tech-stacks/php.svg";
import tsIcon from "../../../resources/tech-stacks/Typescript.svg.png";
import netIcon from "../../../resources/tech-stacks/net.png";
import reactIcon from "../../../resources/tech-stacks/react.png";
import expressIcon from "../../../resources/tech-stacks/express.svg";
import {useEffect} from "react";
import Aos from "aos";

const techStacks = [
    {
        name: "java",
        iconUrl: javaIcon
    },
    {
        name: "C#",
        iconUrl: csharpIcon
    },
    {
        name: "PHP",
        iconUrl: phpIcon
    },
    {
        name: "JavaScript",
        iconUrl: javascriptIcon
    },
    {
        name: "Typescript",
        iconUrl: tsIcon
    }
]

const frameworkStacks = [
    {
        name: "React",
        iconUrl: reactIcon
    },
    {
        name: "net",
        iconUrl: netIcon
    },
    {
        name: "express",
        iconUrl: expressIcon
    }
]

const MyTechStack = ({programmingLanguages, frameworks, others}) => {

    useEffect(() => {
        Aos.init({
            duration: 2000
        });
    }, [])
    
    return(
        <div data-aos="fade-up" id={"techstack"} className={'py-3 flex flex-col justify-center w-full items-center'}>
            <ComponentTitle title={'Tech Stack'} subTitle={'Technologies i have worked on'} />
            <div className={'flex flex-col justify-between mt-3 lg:flex-row md:flex-col sm:flex-col px-3 lg:px-0 '}>
                <div className={''}>
                    <TechCard title={'Programming Language'} techStacks={programmingLanguages} />
                    <TechCard title={'Framework'} techStacks={frameworks} />
                    <TechCard title={'Others'} techStacks={others} />
                </div>
                <div className={'w-full md:w-6/12 sm:w-full'}>
                    <img src={skillIcon} alt={'skill'}/>
                </div>
            </div>
        </div>
    )
}
export default MyTechStack;