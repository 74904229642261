import {useNavigate, useParams,} from "react-router-dom";
import {useContext, useState} from "react";
import {GlobalContext} from "../../Context/GlobalContext";

export const PortfolioFormService = () => {
    const {functionContext, stateContext} = useContext(GlobalContext);
    const {fetchMasterTechList, uploadPhotoApi, deletePhotoApi, addPortfolioApi, deletePhotoBatchApi, fetchPortfolioByIdApi, updatePortfolioApi} = functionContext;
    const {masterTechList} = stateContext;

    const {id} = useParams();
    const navigate = useNavigate();
    const [openModalTechStack, setOpenModalTechStack] = useState(false);
    const [openModalPhoto, setOpenModalPhoto] = useState(false);
    const [openModalCancel, setOpenModalCancel] = useState(false);
    
    const [portfolioForm, setPortfolioForm] = useState({
        title: "",
        link: "",
        description: "",
        publicPhotoId: null,
        photoUrl: null,
        portfolioTechs: [],
        portfolioPhotos: []
    });
    const [selectedPhotoPP, setSelectedPhotoPP] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [techStackList, setTechStackList] = useState([]);
    const [removePhotoList, setRemovePhotoList] = useState([]);
    // const [photoList, setPhotoList] = useState([]);
    const [addPortfolioTechsEdit, setAddPortfolioTechsEdit] = useState([]);
    const [addPhotoListEdit, setAddPhotoListEdit] = useState([]);
    const [removePortfolioTechsEdit, setRemovePortfolioTechsEdit] = useState([]);
    const [removePhotoListEdit, setRemovePhotoListEdit] = useState([]);
    
    const headerTitle = id === undefined ? 'Add Portfolio' : 'Edit Portfolio';

    const fillEditDataForm = () => {
        if (id) {
            fetchPortfolioByIdApi(id, (data) => {
                setPortfolioForm({...portfolioForm, 
                    title: data?.title,
                    link: data?.link,
                    description: data?.description,
                    photoUrl: data?.photoUrl,
                    publicPhotoId: data?.publicPhotoId,
                    portfolioTechs: data?.portfolioTechs,
                    portfolioPhotos: data?.portfolioPhotos
                })
            });
        }
        // console.log(portfolioDetail)
        // console.log(portfolioForm)
    }
    
    const handleAddTech = (tech) => {
        const find = portfolioForm.portfolioTechs.find((t) => t.masterTechId === tech.id);
        if(find) {
            setOpenModalTechStack(false);
            return;
        }
        const save = {
            masterTechId: tech.id,
            name: tech.name
        }
        
        setPortfolioForm({...portfolioForm, portfolioTechs: [...portfolioForm.portfolioTechs, save]});
        setAddPortfolioTechsEdit([...addPortfolioTechsEdit, save]);
        setOpenModalTechStack(false);
        // console.log(techStackList)
    }
    
    const handleRemoveTech = (data) => {
        const values = portfolioForm.portfolioTechs.filter((t) => t.masterTechId !== data.masterTechId);
        setPortfolioForm({...portfolioForm, portfolioTechs: values});
        
        if (id) {
            const valuesEdit = addPortfolioTechsEdit.filter((t) => t.masterTechId !== data.masterTechId);
            setAddPortfolioTechsEdit(valuesEdit);
        }
        
        if (data.id) {
            setRemovePortfolioTechsEdit([...removePortfolioTechsEdit, data])
        }
        // console.log(removePortfolioTechsEdit);
        // console.log(data)
        // console.log(techStackList)
    }
    
    const handleUploadPhoto = (event) => {
        // console.log("Cek Trigger")
        setIsLoading(true);
        try {
            const dataPhoto = event.target.files[0];
            console.log('upload' + isLoading);
            if (portfolioForm.publicPhotoId) {
                deletePhotoApi(portfolioForm.publicPhotoId, () => {
                })
            }

            if(!dataPhoto) {
                alert('No photo found')
            }

            if(dataPhoto) {
                const formData = new FormData();
                formData.append("image", dataPhoto)
                uploadPhotoApi(formData, (data) => {
                    setPortfolioForm({...portfolioForm, publicPhotoId: data.publicId, photoUrl: data.photoUrl})
                });
            }
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false);
        }
        
    }

    const handlePhotoList = (data) => {
        // console.log(data);
        setPortfolioForm({...portfolioForm, portfolioPhotos: [...portfolioForm.portfolioPhotos, data]});
        setAddPhotoListEdit([...addPhotoListEdit, data]);
        setOpenModalPhoto(false);
    }
    
    const handleDeletePhotoList = (publicId) => {
        const findPhoto = portfolioForm.portfolioPhotos.find(p => p.publicId === publicId);
        const value = portfolioForm.portfolioPhotos.filter((p) => p.publicId !== publicId)
        setPortfolioForm({...portfolioForm, portfolioPhotos: value});

        const valueEdit = addPhotoListEdit.filter((p) => p.publicId !== publicId)
        setAddPhotoListEdit(valueEdit);
        
        if (!findPhoto.id) {
            setRemovePhotoList([...removePhotoList, findPhoto]);
        } else {
            setRemovePhotoListEdit([...removePhotoListEdit, findPhoto])
        }
        // console.log(publicId)
    }
    
    const handleOnChangeForm = (e) => {
        let value = e.target.value;
        const name = e.target.name;
        setPortfolioForm({...portfolioForm, [name]: value})
    }
    
    const handleCancel = () => {
        if (removePhotoList.length > 1) {
            deletePhotoBatchApi(removePhotoList, () => {
                navigate('/admin/portfolio', { replace: true })
            })
        } else {
            navigate('/admin/portfolio', { replace: true })
        }
    }

    const updatePortfolio = () => {
        const editData = {
            title: portfolioForm.title,
            link: portfolioForm.link,
            description: portfolioForm.description,
            photoUrl: portfolioForm.photoUrl,
            publicPhotoId: portfolioForm.publicPhotoId,
            addPortfolioTechs: addPortfolioTechsEdit.length < 1 ? null : addPortfolioTechsEdit,
            addPortfolioPhotos: addPhotoListEdit.length < 1 ? null : addPhotoListEdit,
            removePortfolioTechs: removePortfolioTechsEdit.length < 1 ? null : removePortfolioTechsEdit,
            removePortfolioPhotos: removePhotoListEdit.length < 1 ? null : removePhotoListEdit
        }

        if (removePhotoList.length > 1) {
            deletePhotoBatchApi(removePhotoList, () => {
                updatePortfolioApi(id, editData, () => {
                    navigate('/admin/portfolio', { replace: true})
                })
            })
        }
        else {
            updatePortfolioApi(id, editData, () => {
                navigate('/admin/portfolio', { replace: true})
            })
        }
    }
    
    const newPortfolio = () => {
        if (removePhotoList.length > 1) {
            deletePhotoBatchApi(removePhotoList, () => {
                addPortfolioApi(portfolioForm, () => {
                    navigate('/admin/portfolio', { replace: true })
                });
            })
        }
        else {
            addPortfolioApi(portfolioForm, () => {
                navigate('/admin/portfolio', { replace: true })
            });
        }
    }
    
    const submitPortfolio = () => {
        // setPortfolioForm({...portfolioForm, portfolioPhotos: photoList});
        setIsLoading(true);
        try {
            if (!id) {
                newPortfolio();
            } else {
                updatePortfolio();
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }
    
    const portfolioStateFormService = {
        masterTechList, headerTitle, openModalTechStack, setOpenModalTechStack,
        openModalPhoto, setOpenModalPhoto, openModalCancel, setOpenModalCancel,
        portfolioForm, setPortfolioForm, techStackList, setTechStackList,
        isLoading, setIsLoading, selectedPhotoPP, setSelectedPhotoPP
    };
    
    const portfolioFunctionFormService = {
        handleUploadPhoto, fetchMasterTechList, handleCancel, handleOnChangeForm, 
        handleAddTech, handleRemoveTech, handleDeletePhotoList, submitPortfolio, 
        fillEditDataForm, handlePhotoList
    };

    return{
        portfolioFunctionFormService, portfolioStateFormService
    }
}