import htmlIcon from "../resources/tech-stacks/html.png";
import cssIcon from "../resources/tech-stacks/css.png";
import tailwindIcon from "../resources/tech-stacks/tailwind.png";
import reactIcon from "../resources/tech-stacks/react.png";
import netlifyIcon from "../resources/tech-stacks/ntlify.png";

import dashboardAdminPhoto from "../resources/portolios/onlinejob/dashboard.PNG";
import jobDetailPhoto from "../resources/portolios/onlinejob/jobDetail.PNG";
import formJobPhoto from "../resources/portolios/onlinejob/formJob.PNG";
import landingPagePhoto from "../resources/portolios/onlinejob/landingPage.PNG";
import landingPage2Photo from "../resources/portolios/onlinejob/landingPage2.PNG";
import listDataPhoto from "../resources/portolios/onlinejob/listData.PNG";
import loginPhoto from "../resources/portolios/onlinejob/login.PNG";
import changePasswordPhoto from "../resources/portolios/onlinejob/changePassword.PNG";
import bootstrapPhoto from "../resources/tech-stacks/bootstrap5.png";

import instagramIcon from "../resources/instagram-logo-color.png";
import javaIcon from "../resources/tech-stacks/java-original.svg";
import csharpIcon from "../resources/tech-stacks/c-sharp.png";
import phpIcon from "../resources/tech-stacks/php.svg";
import javascriptIcon from "../resources/tech-stacks/javascript-original.svg";
import tsIcon from "../resources/tech-stacks/Typescript.svg.png";
import netIcon from "../resources/tech-stacks/net.png";
import expressIcon from "../resources/tech-stacks/express.svg";
import youtubeIcon from "../resources/youtube.png";

import alibabaIcon from "../resources/tech-stacks/alibaba-cloud-logo.png";
import mendixIcon from "../resources/tech-stacks/mendix.jpg";
import mySqlIcon from "../resources/tech-stacks/mysql.png";
import sqlServerIcon from "../resources/tech-stacks/sql-server.png";
import postgresqlIcon from "../resources/tech-stacks/postgre.png";
import nginxIcon from "../resources/tech-stacks/nginx.png";
import iisIcon from "../resources/tech-stacks/iis.png";
import gitIcon from "../resources/tech-stacks/git.png";
import bootstrapIcon from "../resources/tech-stacks/bootstrap5.png";
import aspNetIcon from "../resources/tech-stacks/aspmvc.png";
import netbeansIcon from "../resources/tech-stacks/netbeans.png";
import intellijIcon from "../resources/tech-stacks/intellij.png";
import xamppIcon from "../resources/tech-stacks/xampp.png";

// Spd
import landingPageSpd from "../resources/portolios/spdweb/landingPage.PNG";
import loginSpd from "../resources/portolios/spdweb/login.PNG";
import registerSpd from "../resources/portolios/spdweb/register.PNG";
import dashboardSaSpd from "../resources/portolios/spdweb/dashboardSA.PNG";
import questionSpd from "../resources/portolios/spdweb/question.PNG";
import answerSpd from "../resources/portolios/spdweb/answer.PNG";
import diagnosisSpd from "../resources/portolios/spdweb/diagnosis.PNG";
import diagnosisDetailSpd from "../resources/portolios/spdweb/diagnosisDetail.PNG";
import relationSpd from "../resources/portolios/spdweb/relation.PNG";
import historySpd from "../resources/portolios/spdweb/testingHistory.PNG";

// Rajawali-Air
import vercelIcon from "../resources/tech-stacks/Vercel.png";
import materialIcon from "../resources/tech-stacks/material.svg";
import landingPageRaIcon from "../resources/portolios/rajawali/landingPage.PNG";
import flightListRaIcon from "../resources/portolios/rajawali/flightResultRa.PNG";
import fillInfoRaIcon from "../resources/portolios/rajawali/fillInformationRa.PNG";
import travelAddOnsRaIcon from "../resources/portolios/rajawali/fillInformationRa.PNG";
import chooseSeatRaIcon from "../resources/portolios/rajawali/chooseSetRa.PNG";
import continuePaymentRaIcon from "../resources/portolios/rajawali/continuePaymentRa.PNG";
import pendingPaymentRaIcon from "../resources/portolios/rajawali/pendingRa.PNG";

// Spd Desktop
import loginSpdDesktopIcon from "../resources/portolios/spddesktop/Login.PNG";
import registerSpdDesktopIcon from "../resources/portolios/spddesktop/Register.PNG";
import spdDesktopIcon1 from "../resources/portolios/spddesktop/1 Beranda.PNG";
import spdDesktopIcon2 from "../resources/portolios/spddesktop/2 Halaman pertanyaan.PNG";
import spdDesktopIcon3 from "../resources/portolios/spddesktop/3 jawaban.PNG";
import spdDesktopIcon4 from "../resources/portolios/spddesktop/4 Diagnosa.PNG";
import spdDesktopIcon5 from "../resources/portolios/spddesktop/5 Relasi.PNG";
import spdDesktopIcon6 from "../resources/portolios/spddesktop/6 Pengujian.PNG";
import spdDesktopIcon7 from "../resources/portolios/spddesktop/7 Riwayat Pengujian.PNG";

// Procurement
import swaggerIcon from "../resources/tech-stacks/swagger.svg";
import profileProcurePhoto from "../resources/portolios/procurement/landing.PNG";
import firstProcurePhoto from "../resources/portolios/procurement/1.PNG";
import secondProcurePhoto from "../resources/portolios/procurement/2.PNG";

export const jobOnline = {
    id: "1",
    title: "Job Online Web Portal",
    description: "To fulfill react js course requirements passed by Sanbercode. I had to finish the job online portal website for final project that has been determined by instructor. " +
        "This website is used for looking for the job who want get more information about job. Admin can create, edit, delete the job, and change password.As public client can see list of job without login. " +
        "In this website has been prepared the API, so i only did in frontend. The tech stacks i used React Js as frontend library, Tailwind for CSS Framework, Netlify for deployment, etc. ",
    linkProject: "https://jobonline.fadhlih.com/",
    tags: [
        {
            id: "1",
            name: "Html",
            iconUrl: htmlIcon
        },
        {
            id: "2",
            name: "Css",
            iconUrl: cssIcon
        },
        {
            id: "11",
            name: "Tailwind",
            iconUrl: tailwindIcon
        },
        {
            id: "12",
            name: "React",
            iconUrl: reactIcon
        },
        {
            id: "13",
            name: "Netlify",
            iconUrl: netlifyIcon
        }

    ],
    imgPhoto: landingPagePhoto,
    appPhotos: [
        landingPagePhoto, landingPage2Photo, jobDetailPhoto, loginPhoto, dashboardAdminPhoto, listDataPhoto, formJobPhoto, changePasswordPhoto
    ]
}

export const spdWeb = {
    id: "2",
    title: "Sistem Pakar Diagnosa Tingkat kecanduan Pornografi (Website Version)",
    description: "Expert system for diagnosing addictive level of porn use Forward Chaining as research method. User logged in can test to check addiction level about porn with answer several questions and the system will check corresponded data. " +
        "In this website has 3 roles are Superadmin, Admin, Normal User. " +
        "Basic user can: test, print result test, see result history, change profile, change password. Admin: can do all normal user features plus can manage question, answer, diagnosis, rules or relation. " +
        "Superadmin: can do all Admin user features plus has  feature user management. For Unauthenticated user can access landing page, login, register, forgot password. " +
        "Tech stack and tools are used: ASP.NET Mvc With React as framework backend and frontend, PostgreSQL as RDBMS, Nginx as Web server, Alibaba Cloud as Private Server",
    linkProject: "http://spd-kecanduan-pornografi.fadhlih.com/",
    tags: [
        {
            id: "1",
            name: "Html",
            iconUrl: htmlIcon
        },
        {
            id: "2",
            name: "Css",
            iconUrl: cssIcon
        },
        {
            id: "11",
            name: "Bootstrap",
            iconUrl: bootstrapPhoto
        },
        {
            id: "12",
            name: "React",
            iconUrl: reactIcon
        },
        {
            id: "13",
            name: "Asp.Net MVC",
            iconUrl: aspNetIcon
        },
        {
            id: "14",
            name: "PostgreSQL",
            iconUrl: postgresqlIcon
        },
        {
            id: "15",
            name: "Nginx",
            iconUrl: iisIcon
        }

    ],
    imgPhoto: landingPageSpd,
    appPhotos: [
        loginSpd, registerSpd, dashboardSaSpd, questionSpd, answerSpd, diagnosisSpd, diagnosisDetailSpd, relationSpd, historySpd
    ]
}

export const spdDesktop = {
    id: "3",
    title: "Sistem Pakar Diagnosa Tingkat Kecanduan Pornografi (Desktop App Version)",
    description: "Expert system for diagnosing addictive level of porn use Forward Chaining as research method. User logged in can test to check addiction level about porn with answer several questions and the system will check corresponded data. " +
        "The functionality same with web version, but it's more simple. In this desktop app has 2 roles are Admin, Normal User. " +
        "Basic user can: test, print result test, see result history. Admin: can do all normal user features plus can manage question, answer, diagnosis, rules or relation. " +
        "Tech stack and tools are used: Java Native, Netbeans, Intellij, Mysql",
    linkProject: null,
    tags: [
        {
            id: "1",
            name: "Java",
            iconUrl: javaIcon
        },
        {
            id: "2",
            name: "Netbeans",
            iconUrl: netbeansIcon
        },
        {
            id: "11",
            name: "Intellij",
            iconUrl: intellijIcon
        },
        {
            id: "12",
            name: "MySql",
            iconUrl: mySqlIcon
        },
        {
            id: "12",
            name: "Xampp",
            iconUrl: xamppIcon
        }

    ],
    imgPhoto: spdDesktopIcon1,
    appPhotos: [
        loginSpdDesktopIcon, registerSpdDesktopIcon, spdDesktopIcon1, spdDesktopIcon2, spdDesktopIcon3, spdDesktopIcon4, spdDesktopIcon5, spdDesktopIcon6, spdDesktopIcon7
    ]
}

export const rajawaliAir = {
    id: "4",
    title: "Rajawali Air Website",
    description: "The Rajawali Air is platform web is used for booking flight ticket Rajawali Airline. This website has 2 role, Admin for manage operational data like Flight, Airport, Schedule, Confirm booked passenger and more. User can buy and order flight ticket, see history, etc. " +
        "Rajawali Air Website is made by team as final project assignment for fulfilled passed requirement in SYNRGY Academy bootcamp. I am one of members in frontend team for building this project. " +
        "Not only frontend, there are backend, Ui/Ux, and Qa. In frontend tech stack and tools we used include: React Typescript, Tailwind, Ci/Cd, Vercel as deployment platform. In Backend using Java Spring, PostgreSQL, " +
        "Ci/Cd, etc.",
    linkProject: "https://rajawali-air.vercel.app/",
    tags: [
        {
            id: "1",
            name: "Html",
            iconUrl: htmlIcon
        },
        {
            id: "2",
            name: "Css",
            iconUrl: cssIcon
        },
        {
            id: "11",
            name: "Tailwind",
            iconUrl: tailwindIcon
        },
        {
            id: "15",
            name: "Material UI",
            iconUrl: materialIcon
        },
        {
            id: "14",
            name: "Typescript",
            iconUrl: tsIcon
        },
        {
            id: "12",
            name: "React",
            iconUrl: reactIcon
        },
        {
            id: "13",
            name: "Vercel",
            iconUrl: vercelIcon
        }

    ],
    imgPhoto: landingPageRaIcon,
    appPhotos: [
        landingPageRaIcon, flightListRaIcon, fillInfoRaIcon, travelAddOnsRaIcon, chooseSeatRaIcon, continuePaymentRaIcon, pendingPaymentRaIcon
    ]
}

export const incomeManagement = {
    id: "1",
    title: "Job Online Website",
    description: "To fulfill react js course requirements passed by Sanbercode. I had to finish the job online portal website for final project that has been determined by instructor. " +
        "This website is used for looking for the job who want get more information about job. Admin can create, edit, delete the job, and change password.As public client can see list of job. " +
        "In this website has been prepared the API, so i only did in frontend. The tech stacks i used React Js as frontend library, Tailwind for CSS Framework, Netlify for deployment, etc. ",
    linkProject: "https://jobonline.fadhlih.com/",
    tags: [
        {
            id: "1",
            name: "Html",
            iconUrl: htmlIcon
        },
        {
            id: "2",
            name: "Css",
            iconUrl: cssIcon
        },
        {
            id: "11",
            name: "Tailwind",
            iconUrl: tailwindIcon
        },
        {
            id: "12",
            name: "React",
            iconUrl: reactIcon
        },
        {
            id: "13",
            name: "Netlify",
            iconUrl: netlifyIcon
        }

    ],
    photo: import("../resources/portolios/onlinejob/landingPage.PNG"),
    appPhotos: [
        "", "",
    ]
}

export const procurement = {
    id: "5",
    title: "Procurement Restful API",
    description: "The mini Rest-Api app that can do payment of products from Customer(Store) to Vendor. Some features like Authentication, Manage Products, Manage Vendor, Payment Transaction and others. Tech stack are used C#, .Net Core API, Sql Server, Swagger. " +
        "This project was created as mini project task when i was training is Enigma Camp Bootcamp. ",
    linkProject: "https://github.com/fadhlihgp/E-Procurement-RestfulApi",
    tags: [
        {
            id: "1",
            name: "C#",
            iconUrl: csharpIcon
        },
        {
            id: "2",
            name: ".Net Core API",
            iconUrl: netIcon
        },
        {
            id: "11",
            name: "Swagger",
            iconUrl: swaggerIcon
        },
        {
            id: "12",
            name: "Sql Server",
            iconUrl: sqlServerIcon
        }

    ],
    imgPhoto: profileProcurePhoto,
    appPhotos: [
        profileProcurePhoto, firstProcurePhoto, secondProcurePhoto
    ]
}

export const restaurantApp = {
    id: "1",
    title: "Job Online Website",
    description: "To fulfill react js course requirements passed by Sanbercode. I had to finish the job online portal website for final project that has been determined by instructor. " +
        "This website is used for looking for the job who want get more information about job. Admin can create, edit, delete the job, and change password.As public client can see list of job. " +
        "In this website has been prepared the API, so i only did in frontend. The tech stacks i used React Js as frontend library, Tailwind for CSS Framework, Netlify for deployment, etc. ",
    linkProject: "https://jobonline.fadhlih.com/",
    tags: [
        {
            id: "1",
            name: "Html",
            iconUrl: htmlIcon
        },
        {
            id: "2",
            name: "Css",
            iconUrl: cssIcon
        },
        {
            id: "11",
            name: "Tailwind",
            iconUrl: tailwindIcon
        },
        {
            id: "12",
            name: "React",
            iconUrl: reactIcon
        },
        {
            id: "13",
            name: "Netlify",
            iconUrl: netlifyIcon
        }

    ],
    photo: profileProcurePhoto,
    appPhotos: [
        firstProcurePhoto, secondProcurePhoto
    ]
}