import React, {useEffect, useState} from "react";
import {HeaderPage} from "../../components/admin/HeaderPage";
import {AdminWidget} from "../../widget/AdminWidget";
import {LandingData} from "../../utils/data.util";
import {ModalConfirmation} from "../../components/ModalConfirmation";
import {ModalFormMasterTech} from "../../components/ModalFormMasterTech";
import {ModalFormSkill} from "../../components/admin/ModalFormSkill";
import SpinnerLoading from "../../components/loadings/SpinnerLoading";
import {TechStackAdminService} from "../Services/TechStackAdminService";

const breadcrumbs = [
    {
        name: "Home",
        linkUrl: "/admin"
    },
    {
        name: "Skill",
        linkUrl: "/admin/skill"
    }
]
export const TechStackAdmin = () => {
    const {handleService, stateService} = TechStackAdminService();

    const {fetchSkills, onChangeSearch, handleDelete,
        handleCloseDeleteModal, handleCloseFormModal} = handleService;
    const {skillId, setSkillId, skills, setSkills,
        fetchSkillStatus, setFetchSkillStatus, skillsFilter, setSkillsFilter,
        openDeleteSkill, setOpenDeleteSkill, openFormSkill, setOpenFormSkill} = stateService

    useEffect(() => {
        fetchSkills();
        setFetchSkillStatus(true);
    }, [fetchSkillStatus, setFetchSkillStatus]);

    useEffect(() => {
        if (skills) {
            setSkillsFilter(skills);
            // console.log(portfolioList);
        }
    }, [skills, setSkills]);

    return(
        <AdminWidget>
            <ModalConfirmation
                openModal={openDeleteSkill}
                setOpenModal={setOpenDeleteSkill}
                onClickYes={handleDelete}
                onClickCancel={handleCloseDeleteModal}
                title={'Are you sure want delete this Skill ?'} />
            
            <ModalFormSkill 
                id={skillId} 
                openModal={openFormSkill} 
                onCloseModal={handleCloseFormModal}
                setFetchStatus={setFetchSkillStatus}
            />

            <HeaderPage linkList={breadcrumbs} tite={'Skill'} />

            <div className={'flex flex-col lg:flex-row md:flex-row sm:flex-row justify-between'}>
                <button onClick={() => {
                    setSkillId(null);
                    setOpenFormSkill(true)
                }}
                        className={'btn bg-green-700 hover:bg-green-900 text-white'}
                >Add Tech</button>
                <input onChange={onChangeSearch} type="text" placeholder="Search" className="input input-bordered w-full max-w-xs" />
            </div>

            <div className={'bg-white rounded-xl'}>
                {(skillsFilter && skillsFilter.length === 0) && (
                    <div className="overflow-x-auto">
                        <table className="table table-zebra">
                            {/* head */}
                            <thead>
                            <tr>
                                <th>No</th>
                                <th>Icon</th>
                                <th>Tech</th>
                                <th>Type</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                        </table>
                        <div className={'text-center p-3 font-semibold'}>
                            No Data
                        </div>
                    </div>
                )}

                {!skillsFilter && (
                    <SpinnerLoading text={'Loading fetch skills data'} />
                )}

                {(skillsFilter && skillsFilter.length > 0) && (
                    <div className="overflow-x-auto">
                        <table className="table table-zebra">
                            {/* head */}
                            <thead>
                            <tr>
                                <th>No</th>
                                <th>Icon</th>
                                <th>Tech</th>
                                <th>Type</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>

                            {skillsFilter.map((data, index) => (
                                <tr key={index}>
                                    <th>{index+1}</th>
                                    <td>
                                        <div className="avatar">
                                            <div className="mask mask-squircle w-12 h-12">
                                                <img src={data.iconUrl} alt="Avatar Tailwind CSS Component" />
                                            </div>
                                        </div>
                                    </td>
                                    <td>{data.name}</td>
                                    <td>{data.type}</td>
                                    <td>
                                        <div className={'flex gap-1'}>
                                            <button
                                                className={'btn btn-sm bg-green-600 text-white hover:bg-green-800'}
                                                onClick={() => {
                                                    setSkillId(data.id);
                                                    setOpenFormSkill(true);
                                                }}
                                            >Edit</button>
                                            <button className={'btn btn-sm bg-red-600 text-white hover:bg-red-800'}
                                                    onClick={() => {
                                                        setSkillId(data.id)
                                                        setOpenDeleteSkill(true)
                                                    }}>Delete</button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            
                            </tbody>
                        </table>
                    </div>
                )}
                
            </div>
        </AdminWidget>
    )
}