import {useEffect, useState} from "react";
import backgroundImage from "../../../resources/background.png";
import Aos from "aos";
import {useNavigate} from "react-router-dom";
import {MaximumWordLength} from "../../../utils/MaximumWordLength";
const PortfolioCard = ({id, title, description, tags, imageBackground}) => {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();
    const setHoverTrue = () => {
        setIsHovered(true);
    }
    
    const setHoverFalse = () => {
        setIsHovered(false)
    }

    useEffect(() => {
        Aos.init({
            duration: 1200
        });
    }, [])

    const handleClick = () => {
        window.scrollTo(0, 0);
        navigate("/portfolio/" + id)
    };
    
    return(
        <div data-aos="zoom-in" className="card-container rounded-2xl w-11/12 lg:w-80 bg-base-100 shadow-xl">
            {!isHovered && (
                <figure onMouseEnter={setHoverTrue} onMouseLeave={setHoverFalse}>
                    <img className={'rounded-2xl'} src={imageBackground} alt="Shoes" />
                </figure>
            )}
            {isHovered && (
                <div className="card w-11/12 lg:w-80 bg-base-100 shadow-xl image-full"
                     onMouseLeave={setHoverFalse}
                >
                    <figure><img src={imageBackground} alt="Shoes" /></figure>
                    <div className="card-body flex justify-center items-center">
                        <div className="card-actions mx-auto">
                            <button className="btn btn-primary btn-sm"
                                    onClick={handleClick}
                            >View</button>
                        </div>
                    </div>
                </div>
            )}
            
            <div className="card-body">
                <h2 className="card-title">
                    {MaximumWordLength(title, 25)}
                    {/*<div className="badge badge-secondary">NEW</div>*/}
                </h2>
                <p>{MaximumWordLength(description, 50)}</p>
                <div className="card-actions justify-start">
                    {tags.slice(0, 6).map(({name}) => (
                        <div className="badge badge-outline">{name}</div>
                    ))}
                    {/*<div className="badge badge-outline">Fashion</div>*/}
                    {/*<div className="badge badge-outline">Products</div>*/}
                </div>
            </div>
        </div>
    )
}
export default PortfolioCard;