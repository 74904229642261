const TechPortfolioCard = ({techStacks}) => {
    return(
        <div className={'mb-6'}>
            <div className={'grid grid-cols-3 lg:grid-cols-7 md:grid-cols-5 gap-5 items-center '}>
                {techStacks.map(({name, iconUrl}, index) => (
                    <div className={'flex flex-col items-center gap-2'} key={index}>
                        <img className={'lg:h-[70px] md:h-[69px] h-[50px] w-auto'} src={iconUrl} alt={name}/>
                        <p className={'hidden lg:block md:block'}>{name}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default TechPortfolioCard;