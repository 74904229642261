import {useContext, useState} from "react";
import {GlobalContext} from "../../Context/GlobalContext";

export const MasterTechService = () => {
    const {functionContext, stateContext} = useContext(GlobalContext);
    const {deleteMasterTechApi, fetchMasterTechListApi} = functionContext;
    const {masterTechList, setMasterTechList} = stateContext;
    
    const [masterTechId, setMasterTechId] = useState(null);
    const [fetchMasterTechStatus, setFetchMasterTechStatus] = useState(false);
    const [masterTechListFilter, setMasterTechListFilter] = useState(null);
    const [openDeleteMaster, setOpenDeleteMaster] = useState(false);
    const [openFormMaster, setOpenFormMaster] = useState(false);
    
    const fetchMasterTechs = () => {
        fetchMasterTechListApi();
        setFetchMasterTechStatus(true);
    }
    
    const onChangeSearch = (e) => {
        let value = e.target.value.toLowerCase();
        if (value === "") {
            setMasterTechListFilter(masterTechList)
        }
        const filteredList = masterTechList.filter((p) => p.name.toLowerCase().includes(value));
        setMasterTechListFilter(filteredList);
    }
    
    const handleDelete = () => {
        deleteMasterTechApi(masterTechId, () => {
            setFetchMasterTechStatus(false);
            setOpenDeleteMaster(false);
        });
    }
    
    const handleButtonAdd = () => {
        setMasterTechId(null);
        setOpenFormMaster(true);
    }
    
    const handleCloseDeleteModal = () => {
        setMasterTechId(null);
        setOpenDeleteMaster(false);
    }
    
    const handleCloseFormModal = () => {
        setMasterTechId(null);
        setOpenFormMaster(false);
    }
    
    // Form
    const handleOpenEditForm = (id) => {
        setMasterTechId(id);
        // fetchMasterTechDetail(id);
        setOpenFormMaster(true)
    }
    
    const handleService = {
        fetchMasterTechs, onChangeSearch, handleDelete, 
        handleCloseDeleteModal,
        handleCloseFormModal, handleOpenEditForm, handleButtonAdd
    };
    
    const stateService = {
        masterTechId, setMasterTechId, fetchMasterTechStatus, setFetchMasterTechStatus,
        masterTechList, setMasterTechList, masterTechListFilter, setMasterTechListFilter,
        openDeleteMaster, setOpenDeleteMaster, openFormMaster, setOpenFormMaster
    }
    
    return {
        handleService, stateService
    }
}