import {AdminWidget} from "../../widget/AdminWidget";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {axiosConfig} from "../../configs/axiosConfig";
import {toast} from "react-hot-toast";
import SpinnerLoading from "../../components/loadings/SpinnerLoading";

export const Account = () => {
    const [accountForm, setAccountForm] = useState({
        username: "",
        email: ""
    });
    const [fetchStatus, setFetchStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const fetchAccountDetail = () => {
        axiosConfig.get('account/currentUser')
            .then(({data}) => {
                setAccountForm({email: data.data.email, username: data.data.username});
            })
            .catch(err => {
                console.log(err);
                toast.error('Failed get account')
            })
    }
    
    useEffect(() => {
        fetchAccountDetail();
        setFetchStatus(true);
    }, [fetchStatus, setFetchStatus])
    
    const onChangeInput = (e) => {
        const {value, name} = e.target;
        setAccountForm({...accountForm, [name]: value})
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        axiosConfig.put('account/update', accountForm)
            .then(({data}) => {
                toast.success(data.message);
                setFetchStatus(false)
            })
            .catch(err => {
                console.log(err);
                toast.error('Failed updated data')
            })
            .finally(() => {
                setIsLoading(false)
            })
    }
    
    return(
        <AdminWidget>
            {isLoading && (
                <SpinnerLoading text={'Updating data ...'} />
            )}
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm h-[100%]">
                <h1 className={'text-center font-bold text-lg mb-3'}>Account</h1>
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Email address
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                value={accountForm.email}
                                onChange={onChangeInput}
                                autoComplete="email"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                            Username
                        </label>
                        <div className="mt-2">
                            <input
                                id="text"
                                name="username"
                                type="username"
                                onChange={onChangeInput}
                                value={accountForm.username}
                                autoComplete="username"
                                required
                                className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    
                    <div>
                        <div className="text-sm">
                            <Link to={'/admin/change-password'} className="font-semibold text-indigo-600 hover:text-indigo-500">
                                Change Password
                            </Link>
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </AdminWidget>
    )
}