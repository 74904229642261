import ComponentTitle from "../ComponentTitle";
import FormSendEmail from "./FormSendMail";
import DetailContactMe from "./DetailContactMe";

const ContactMe = ({detailContact}) => {
    return(
        <div id={"contact"} className={'px-8 w-full py-10 bg-base-300'}>
            <ComponentTitle title={'Contact Me'} />
            <div className={'flex flex-col gap-5 lg:flex-row md:flex-row w-full justify-center'}>
                <div className={'w-full lg:w-2/5 md:w-2/5'}>
                    <FormSendEmail />
                </div>
                <div className={'w-full lg:w-2/5 md:w-2/5'}>
                    <DetailContactMe detailContact={detailContact} />
                </div>
            </div>
        </div>
    )
}
export default ContactMe;