import Hero from "../components/landingPages/Hero/Hero";
import MyTechStack from "../components/landingPages/MyTechStack";
import Education from "../components/landingPages/Education";
import React, {useEffect, useState} from "react";
import Experience from "../components/landingPages/Experience";
import Portfolio from "../components/landingPages/Portfolio";
import ContactMe from "../components/landingPages/ContactMe";
import LandingPageWidget from "../widget/LandingPageWidget";
import {LandingData} from "../utils/data.util";
import {axiosConfig} from "../configs/axiosConfig";
import {Log as toastr} from "oidc-client";
import {toast} from "react-hot-toast";
import SpinnerLoading from "../components/loadings/SpinnerLoading";
import {ErrorComponent} from "../components/ErrorComponent";

const LandingPage = () => {
    const [dashboardValue, setDashboardValue] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    
    const fetchDataDashboard = () => {
        axiosConfig.get(`dashboard`)
            .then(({data}) => {
                setDashboardValue(data.data)
            })
            .catch((error) => {
                toast.error("Error when fetching data");
                console.log(error);
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }
    
    useEffect(() => {
        fetchDataDashboard()
    }, []);
    
    return(
        <>
            {(!isLoading && isError) && (
                // <SpinnerLoading text={'Loading'} />
                <ErrorComponent />
            )}

            {(isLoading && !dashboardValue) && (
                <SpinnerLoading text={'Loading'} />
            )}
            
            {!isLoading && (
                <LandingPageWidget>

                    {(!isLoading && dashboardValue) && (
                        <>
                            <Hero heroInformation={dashboardValue?.profile} socialMedias={dashboardValue?.socialMedias} />
                            <Education educationList={dashboardValue?.education} />
                            <Experience experiences={dashboardValue?.experiences} />
                            <MyTechStack
                                programmingLanguages={dashboardValue?.skills.programmingLanguage}
                                frameworks={dashboardValue?.skills.frameworks}
                                others={dashboardValue?.skills.others}
                            />
                            <Portfolio portfolios={dashboardValue?.portfolios} />
                            <ContactMe detailContact={dashboardValue?.profile} />
                        </>
                    )}

                </LandingPageWidget>
            )}
        </>
        
    )
}
export default LandingPage;