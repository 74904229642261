export const LinkContainer = ({linkUrl, imageUrl, alt}) => {
    return(
        <div>
            <a href={linkUrl} target={"_blank"}><img src={imageUrl} alt={alt} width={'32px'} className={'transition ease-in-out hover:-translate-y-1 hover:scale-110 duration-300'} /></a>
        </div>
    )
}
const SocialMedia = ({socialMedias}) => {
    return(
        <section className={'flex gap-5'}>
            {socialMedias?.map(({iconUrl, name, linkUrl}) => (
                <LinkContainer linkUrl={linkUrl} alt={name} imageUrl={iconUrl} />
            ))}
            {/*<LinkContainer linkUrl={'https://instagram.com/fadhlih17'} alt={'instagram'} imageUrl={instagramIcon} />*/}
            {/*<LinkContainer linkUrl={'https://linkedin.com/fadhlih-girindra-putra'} alt={'linkedin'} imageUrl={linkedinIcon} />*/}
            {/*<LinkContainer linkUrl={'https://github.com/fadhlihgp'} alt={'github'} imageUrl={githubIcon} />*/}
        </section>
    )
}
export default SocialMedia;