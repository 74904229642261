import {AdminWidget} from "../../widget/AdminWidget";
import {Link} from "react-router-dom";
import {useState} from "react";
import {axiosConfig} from "../../configs/axiosConfig";
import {toast} from "react-hot-toast";
import SpinnerLoading from "../../components/loadings/SpinnerLoading";

export const ChangePassword = () => {
    
    const [isLoading, setIsLoading] = useState(false);
    const [passwordForm, setPasswordForm] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    
    const handleOnChangeInput = (e) => {
        const { value, name } = e.target;
        setPasswordForm({...passwordForm, [name]: value})
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (passwordForm.newPassword !== passwordForm.confirmPassword) {
            toast.error('New password and confirm password must be the same');
        } else {
            setIsLoading(true);
            axiosConfig.put('account/changePassword',passwordForm)
                .then(({data}) => {
                    toast.success(data.message);
                    setPasswordForm({newPassword: "", oldPassword: "", confirmPassword: ""})
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                    console.log(error)
                })
                .finally(() => {
                    setIsLoading(false);
                })
        }
        
    }
    
    return(
        <AdminWidget>
            {isLoading && (
                <SpinnerLoading text={'Change password is processing'} />
            )}
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm h-[100%]">
                <h1 className={'text-center font-bold text-lg mb-3'}>Change Password</h1>
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="oldPassword" className="block text-sm font-medium leading-6 text-gray-900">
                            Old Password
                        </label>
                        <div className="mt-2">
                            <input
                                id="oldPassword"
                                name="oldPassword"
                                type="password"
                                value={passwordForm.oldPassword}
                                onChange={handleOnChangeInput}
                                autoComplete="oldPassword"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="newPassword" className="block text-sm font-medium leading-6 text-gray-900">
                            New Password
                        </label>
                        <div className="mt-2">
                            <input
                                id="newPassword"
                                name="newPassword"
                                type="password"
                                value={passwordForm.newPassword}
                                onChange={handleOnChangeInput}
                                autoComplete="newPassword"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900">
                            Confirm New Password
                        </label>
                        <div className="mt-2">
                            <input
                                id="confirmPassword"
                                name="confirmPassword"
                                type="password"
                                onChange={handleOnChangeInput}
                                value={passwordForm.confirmPassword}
                                autoComplete="confirmPassword"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    
                    <div>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-green-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Change
                        </button>
                    </div>
                </form>
            </div>
        </AdminWidget>
    )
}