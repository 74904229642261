import {HeaderAdmin} from "../HeaderAdmin";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ModalAddTechStack} from "../ModalAddTechStack";
import {ExperienceFormService} from "../../../pages/Services/ExperienceFormService";
import SpinnerLoading from "../../loadings/SpinnerLoading";

const linkList = [
    {
        name: "Home",
        linkUrl: "/admin"
    },
    {
        name: "Experience",
        linkUrl: "/admin/experience"
    },
    {
        name: "Form",
        linkUrl: "/"
    }
]

const ports = [
    {
        name: "React"
    },
    {
        name: ".NET"
    },
]
export const ExperienceFormComponent = () => {
    const {id} = useParams();
    const title = id === undefined ? "Add Education" : "Edit Education";
    const navigate = useNavigate();
    // const [openModalTechStack, setOpenModalTechStack] = useState(false);
    const {handleService, stateService} = ExperienceFormService();
    
    const {handleAddTech, createExperience, updateExperience, handleOnChangeInput, 
        handleSubmit, handleRemoveTech, fetchDataDetailExperiences} = handleService;
    const {isLoading, setIsLoading, openModalTechStack, setOpenModalTechStack,
        addExperienceTechsEdit, setAddExperienceTechsEdit, removeExperienceTechsEdit, setRemoveExperienceTechsEdit,
        experienceForm, setExperienceForm} = stateService;
    
    useEffect(() => {
        if(id) {
            fetchDataDetailExperiences()
        }
    }, [id])
    
    return(
        <>
            {isLoading && (
                <SpinnerLoading text={'Saving data'} />
            )}
            
            <ModalAddTechStack 
                openModal={openModalTechStack} 
                onCloseModal={() => setOpenModalTechStack(false)}
                handleAdd={handleAddTech}
            />
            <form onSubmit={handleSubmit}>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                        <HeaderAdmin
                            title={title}
                            linkList={linkList}
                            subTitle={'This information will be displayed publicly so be careful what you share.'} />

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-2 sm:col-start-1">
                                <label htmlFor="position" className="block text-sm font-medium leading-6 text-gray-900">
                                    Position
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        value={experienceForm.position}
                                        onChange={handleOnChangeInput}
                                        name="position"
                                        id="position"
                                        autoComplete="address-level2"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="company" className="block text-sm font-medium leading-6 text-gray-900">
                                    Company
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        value={experienceForm.company}
                                        onChange={handleOnChangeInput}
                                        name="company"
                                        id="company"
                                        autoComplete="address-level1"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2 sm:col-start-1">
                                <label htmlFor="start" className="block text-sm font-medium leading-6 text-gray-900">
                                    Start
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        value={experienceForm.start}
                                        onChange={handleOnChangeInput}
                                        name="start"
                                        id="start"
                                        autoComplete="address-level2"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="end" className="block text-sm font-medium leading-6 text-gray-900">
                                    End
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        value={experienceForm.end}
                                        onChange={handleOnChangeInput}
                                        name="end"
                                        id="end"
                                        autoComplete="address-level1"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2 sm:col-start-1">
                                <label htmlFor="workingType" className="block text-sm font-medium leading-6 text-gray-900">
                                    Working Type
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        onChange={handleOnChangeInput}
                                        value={experienceForm.workingType}
                                        name="workingType"
                                        id="workingType"
                                        autoComplete="address-level1"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="place" className="block text-sm font-medium leading-6 text-gray-900">
                                    Place
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        onChange={handleOnChangeInput}
                                        value={experienceForm.place}
                                        name="place"
                                        id="place"
                                        autoComplete="address-level1"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            
                            <div className="sm:col-span-4">
                                <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Tech Stack
                                </label>

                                <div className="card-actions justify-start mt-3">
                                    {experienceForm.addExperienceTechs.map((data) => (
                                        <div key={data.id} className="badge badge-outline">
                                            {data.name}
                                            <button onClick={() => handleRemoveTech(data)} className={'ml-3'}><b>x</b></button>
                                        </div>
                                    ))}
                                </div>

                                <div className={'mt-2'}>
                                    <button className={'btn btn-sm btn-primary'} type={"button"}
                                            onClick={() => setOpenModalTechStack(true)}>Add TechStacks</button>
                                </div>
                            </div>
                            
                            <div className="col-span-full">
                                <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                    Description
                                </label>
                                <div className="mt-2">
                                <textarea
                                    value={experienceForm.description}
                                    onChange={handleOnChangeInput}
                                    id="description"
                                    name="description"
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    defaultValue={''}
                                />
                                </div>
                            </div>
                            
                        </div>

                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-3">
                    <button onClick={() => navigate('/admin/experience')} type="button" className="text-sm font-semibold leading-6 text-gray-900 bg-white btn btn-sm">
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn-sm btn bg-blue-600 hover:bg-blue-800 text-white"
                    >
                        Save
                    </button>
                </div>
            </form>
        </>
    )
}