import React, { useState } from "react";

export const PortfolioPhotosForm = ({ photo, handleDelete }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            className="relative"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <img
                src={photo}
                alt="photo"
                width="256px"
                style={{ opacity: isHovered ? "0.2" : "1" }}
            />
            {isHovered && (
                <button
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red-600 text-white hover:bg-red-800 px-4 py-2 rounded-lg"
                    onClick={handleDelete}
                    type={"button"}
                >
                    Delete
                </button>
            )}
        </div>
    );
};
