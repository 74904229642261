import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import {Alert} from "flowbite-react";
import {axiosConfig} from "../configs/axiosConfig";
import Cookies from "js-cookie";
import {toast} from "react-hot-toast";
import SpinnerLoading from "../components/loadings/SpinnerLoading";

export default function Login() {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    
    const handleLogin = (e) => {
        e.preventDefault();
        setIsLoading(true);
        axiosConfig.post('account/login', {
            email: e.target.email.value,
            password: e.target.password.value
        })
            .then(({data}) => {
                toast.success(data.message);
                Cookies.set("token", data.data.token, {expires: 2} );
                // navigate('/admin');
                window.location.href = '/admin';
            })
            .catch((error) => {
                setErrorMessage(error.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }
    
    const onClickInput = (e) => {
        setErrorMessage(null);
    }
    
    return (
        <>
            {isLoading && (
                <SpinnerLoading text={'Loading login ...'} />
            )}
            <div className="flex h-full flex-1 flex-col justify-center items-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <Link to={'/'}>
                        <img
                            className="mx-auto h-10 w-auto"
                            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                            alt="Your Company"
                        />
                    </Link>
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Sign in to your account
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    
                    {errorMessage && (
                        <Alert color="failure" className={'mb-2'}>
                            <span className="font-medium">Info alert!</span> {errorMessage}
                        </Alert>
                    )}
                    
                    <form onSubmit={handleLogin} className="space-y-6" action="#" method="POST">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email or Username
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="text"
                                    autoComplete="email"
                                    onClick={onClickInput}
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    onClick={onClickInput}
                                    autoComplete="current-password"
                                    required
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Sign in
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
