import {Badge} from "flowbite-react";

const EducationValue = ({education}) => {
    const test = (desc) => {
        console.log(desc)
    }
    return(
        <div className={'mb-8'}>
            <h2 className={'text-2xl mt-0 pt-0 font-semibold text-blue-800'}>{education.subject}</h2>
            {/*<div className={'bg-blue-300 text-blue-950 w-auto font-semibold'}><strong>2020 - Present</strong></div>*/}
            <Badge size={'sm'} color="success" className={'my-2 w-full lg:w-4/6 md:w-2/6 sm:3/6 justify-center'}><strong>{education.start} - {education.end}</strong></Badge>
            <h2 className={'text-lg font-bold text-black'}>{education.institution}</h2>
            <p className={'text-slate-500 text-sm'} onClick={() => test(education.description)}>
                {education.description}
                {/*"Test <a href="https://google.com" >Link</a>"*/}
            </p>
        </div>
    )
}
export default EducationValue;