import {useContext, useState} from "react";
import {GlobalContext} from "../../Context/GlobalContext";

export const SocialMediaService = () => {
    const {functionContext} = useContext(GlobalContext);
    const {fetchAllSocialMediaApi, deleteSocialMediaApi} = functionContext;
    
    // const {socialMedias, setSocialMedias} = useState(null);
    const [socialMedias, setSocialMedias] = useState(null);
    const [socialMediaId, setSocialMediaId] = useState(null);
    const [fetchSocialMediaStatus, setFetchSocialMediaStatus] = useState(false);
    const [socialMediasFilter, setSocialMediasFilter] = useState(null);
    const [openDeleteSocialMedia, setOpenDeleteSocialMedia] = useState(false);
    const [openFormSocialMedia, setOpenFormSocialMedia] = useState(false);

    const fetchSocialMedias = () => {
        fetchAllSocialMediaApi((data) => {
            setSocialMedias(data);
        });
    }
    
    const onChangeSearch = (e) => {
        let value = e.target.value.toLowerCase();
        if (value === "") {
            setSocialMediasFilter(socialMedias)
        }
        const filteredList = socialMedias.filter((p) => p.name.toLowerCase().includes(value));
        setSocialMediasFilter(filteredList);
    }

    const handleDelete = () => {
        deleteSocialMediaApi(socialMediaId, () => {
            setFetchSocialMediaStatus(false);
            setOpenDeleteSocialMedia(false);
        });
    }

    const handleButtonAdd = () => {
        setSocialMediaId(null);
        setOpenFormSocialMedia(true);
    }

    const handleCloseDeleteModal = () => {
        setSocialMediaId(null);
        setOpenDeleteSocialMedia(false);
    }
    
    const handleCloseFormModal = () => {
        setSocialMediaId(null);
        setOpenFormSocialMedia(false);
    }

    // Form
    const handleOpenEditForm = (id) => {
        setSocialMediaId(id);
        setOpenFormSocialMedia(true)
    }
    
    const stateService = {
        socialMedias, setSocialMedias, socialMediaId, setSocialMediaId,
        fetchSocialMediaStatus, setFetchSocialMediaStatus, socialMediasFilter, setSocialMediasFilter,
        openDeleteSocialMedia, setOpenDeleteSocialMedia, openFormSocialMedia, setOpenFormSocialMedia
    }
    
    const handleService = {
        fetchSocialMedias, onChangeSearch, handleDelete, handleButtonAdd,
        handleCloseDeleteModal, handleCloseFormModal, handleOpenEditForm
    }
    
    return {
        stateService, handleService
    }
}