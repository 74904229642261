import React, {useEffect} from "react";
import {ModalAddTechStack} from "../ModalAddTechStack";
import {PortfolioPhotosForm} from "./PortfolioPhotosForm";
import {ModalAddPhotoPortfolio} from "../ModalAddPhotoPortfolio";
import {HeaderAdmin} from "../HeaderAdmin";
import {PortfolioFormService} from "../../../pages/Services/PortfolioFormService";
import {ModalConfirmation} from "../../ModalConfirmation";
import noImage from "../../../resources/noimage.png";
import SpinnerLoading from "../../loadings/SpinnerLoading";

const linkList = [
    {
        name: "Home",
        linkUrl: "/admin"
    },
    {
        name: "Portfolio",
        linkUrl: "/admin/portfolio"
    },
    {
        name: "Form",
        linkUrl: "/"
    }
]
export const PortfolioFormComponent = () => {
    const {portfolioStateFormService, portfolioFunctionFormService, } = PortfolioFormService();
    
    const {headerTitle, openModalTechStack, setOpenModalTechStack,
        openModalPhoto, setOpenModalPhoto, openModalCancel, setOpenModalCancel,
        portfolioForm, isLoading,
    } = portfolioStateFormService
    
    const { handleCancel, handleOnChangeForm, handleAddTech, 
        handleRemoveTech, handleUploadPhoto, handleDeletePhotoList, submitPortfolio, 
        fillEditDataForm, handlePhotoList} = portfolioFunctionFormService;
    
    useEffect(() => {
        fillEditDataForm()
    }, []);
    
    return(
        <React.Fragment>
            {isLoading && (
                <SpinnerLoading text={'Loading'} />
            )}
            <ModalAddTechStack
                openModal={openModalTechStack} 
                onCloseModal={() => setOpenModalTechStack(false)}
                handleAdd={handleAddTech}
            />
            
            <ModalConfirmation 
                openModal={openModalCancel} 
                setOpenModal={setOpenModalCancel} 
                title={'Are you sure want cancel all changes ?'} 
                onClickCancel={() => setOpenModalCancel(false)}
                onClickYes={handleCancel}
            />
            
            <ModalAddPhotoPortfolio 
                openModal={openModalPhoto} 
                onCloseModal={() => setOpenModalPhoto(false)}
                handleAddPhoto={handlePhotoList}
            />

            <HeaderAdmin
                title={headerTitle}
                linkList={linkList}
                subTitle={'This information will be displayed publicly so be careful what you share.'} />

            <div className={'flex flex-col lg:flex-row md:flex-row gap-5'}>
                <div className={'w-[30%] mt-2'}>
                    <img src={portfolioForm.photoUrl ?? noImage} className={'w-full'} alt={'photo'}/>
                    <p className={'font-semibold'}>Change Photo</p>
                    <input type="file"
                           className="file-input file-input-bordered file-input-sm w-full max-w-xs mt-2"
                           onChange={handleUploadPhoto}
                    />
                    {/*<button onClick={uploadPhotoPP} className={'btn mt-2 btn-sm btn-success text-white'}>Upload</button>*/}
                </div>

                <div className={'w-[70%]'}>
                    <form>
                        <div className="space-y-12">
                            <div className="border-b border-gray-900/10 pb-12">
                                <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-4">
                                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                            Project Name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="title"
                                                id="title1"
                                                onChange={handleOnChangeForm}
                                                value={portfolioForm.title}
                                                autoComplete="given-name"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                                            Link
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="link"
                                                id="region1"
                                                onChange={handleOnChangeForm}
                                                value={portfolioForm.link}
                                                autoComplete="address-level1"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-full">
                                        <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                            Description
                                        </label>
                                        <div className="mt-2">
                                <textarea
                                    id="description1"
                                    name="description"
                                    value={portfolioForm.description}
                                    onChange={handleOnChangeForm}
                                    rows={3}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-36"
                                    defaultValue={''}
                                />
                                        </div>
                                        <p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about Project.</p>
                                    </div>

                                    <div className="sm:col-span-4">
                                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                            Tech Stack
                                        </label>

                                        <div className="card-actions justify-start mt-3">
                                            {portfolioForm.portfolioTechs?.map((data) => (
                                                <div className="badge badge-outline" key={data.masterTechId}>
                                                    {data.name}
                                                    <button type={"button"} className={'ml-3'} onClick={() => handleRemoveTech(data)}><b>x</b></button>
                                                </div>
                                            ))}
                                        </div>

                                        <div className={'mt-2'}>
                                            <button className={'btn btn-sm btn-primary'} type={"button"}
                                                    onClick={() => setOpenModalTechStack(true)}>Add TechStacks</button>
                                        </div>
                                    </div>

                                    <div className="sm:col-span-full">
                                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                            Photos
                                        </label>

                                        <div className="card-actions justify-start mt-3">
                                            {portfolioForm.portfolioPhotos.map((data) => (
                                                <PortfolioPhotosForm 
                                                    photo={data.photoUrl}
                                                    handleDelete={() => handleDeletePhotoList(data.publicId)}
                                                />
                                            ))}
                                        </div>

                                        <div className={'mt-2'}>
                                            <button className={'btn btn-sm btn-success text-white'} type={"button"}
                                                    onClick={() => setOpenModalPhoto(true)}>Add Photo</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="mt-6 flex items-center justify-end gap-x-3">
                            <button
                                onClick={() => setOpenModalCancel(true)}
                                type={"button"}
                                className="text-sm font-semibold leading-6 text-gray-900 bg-white btn btn-sm">
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={submitPortfolio}
                                className="btn-sm btn bg-blue-600 hover:bg-blue-800 text-white"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>

            </div>
        </React.Fragment>
        
    )
}