import BreadcrumbsComponent from "../BreadcrumbsComponent";
import React from "react";

export const HeaderAdmin = ({title, linkList, subTitle}) => {
    return(
        <div>
            <h2 className="font-bold text-lg leading-7 text-gray-900">{title}</h2>
            <BreadcrumbsComponent linkList={linkList} />
            <p className="text-sm leading-6 text-gray-600">
                {subTitle}
            </p>
        </div>
    )
}