import {Link} from "react-router-dom";
import {MaximumWordLength} from "../../utils/MaximumWordLength";
import moment from "moment";

export const InboxComponent = ({people}) => {
    
    const styleDiv = {
        backgroundColor: people.isRead ? 'white' : '#E3E1D9',
        ':hover': {
            backgroundColor: '#B4B4B8'
        }
    }
    
    return(
        <ul role="list" className="">
            {people.map((inbox) => (
                <>
                    <Link to={'/admin/inbox/detail/' + inbox.id} key={inbox.id} className="flex gap-x-6 py-5 hover:bg-slate-300 px-8 w-full" 
                          style={{backgroundColor: inbox.isRead ? 'white' : '#E3E1D9'}}>
                        <div className="flex min-w-0 w-full gap-x-4">
                            <div className="min-w-0 flex-auto">
                                <p className="text-sm font-semibold leading-6 text-gray-900">{inbox.name}</p>
                                <p className="mt-1 truncate text-xs leading-5 text-gray-500">{inbox.email}</p>
                            </div>
                        </div>

                        <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-start w-[40%]">
                            <p className="text-sm leading-6 text-gray-900 font-semibold">{inbox.subject}</p>
                            <p className="mt-1 truncate text-xs leading-5 text-gray-500">{MaximumWordLength(inbox.message, 70)}</p>
                        </div>

                        <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end w-[30%]">
                            <p className="text-sm leading-6 text-gray-900">{moment(inbox.received).format('MMMM Do YYYY, h:mm:ss a')}</p>
                        </div>
                    </Link>
                    <div className="divider"></div>
                </>
            ))}
        </ul>
    )
}