const messages = [
    {
        title: "Let's Get in Touch",
        message: "I'm currently open to new freelance or remote opportunities work. If you have an exciting project that you think I'd be a good fit for, \n" +
            " I'd love to hear from you. Please feel free to reach out using the contact form. Let's collaborate and bring your ideas to life!"
    },
    {
        title: "Phone",
        message: "-"
    },
    {
        title: "Email",
        message: "fadhlihgp@gmail.com"
    },
    // {
    //     title: "Website",
    //     message: "http://www.fadhlih.com"
    // }
]
const ContactMeMessage = ({title, message}) => {
    return(
        <div>
            <h1 className={'font-bold text-xl'}>{title}</h1>
            <p>{message}</p>
        </div>
    )
}
const ContactMeMessageLink = ({title, message}) => {
    return(
        <div>
            <h1 className={'font-bold text-xl'}>{title}</h1>
            <a href={message} target="_blank" className={'hover:text-teal-600'}>{message}</a>
        </div>
    )
}
const DetailContactMe = ({detailContact}) => {
    return(
        <div className={'flex flex-col gap-5'}>
            <ContactMeMessage title={"Email"} message={detailContact?.email} />
            <ContactMeMessage title={"Phone"} message={detailContact?.phone} />
            <ContactMeMessageLink title={'Website'} message={detailContact?.websiteUrl} />
        </div>
    )
}
export default DetailContactMe;