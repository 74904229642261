import {useContext, useState} from "react";
import {GlobalContext} from "../../Context/GlobalContext";
import {useNavigate, useParams} from "react-router-dom";

export const ExperienceFormService = () => {
    const {id} = useParams();
    const {functionContext} = useContext(GlobalContext);
    const {fetchExperienceByIdApi, createExperienceApi, updateExperienceApi} = functionContext;
    const navigate = useNavigate();
    
    const [isLoading, setIsLoading] = useState(false);
    const [openModalTechStack, setOpenModalTechStack] = useState(false);
    const [addExperienceTechsEdit, setAddExperienceTechsEdit] = useState([]);
    const [removeExperienceTechsEdit, setRemoveExperienceTechsEdit] = useState([]);
    const [experienceForm, setExperienceForm] = useState({
        position: "",
        company: "",
        place: "",
        workingType: "",
        start: "",
        end: "",
        description: "",
        addExperienceTechs: []
    });
    
    const handleAddTech = (tech) => {
        const find = experienceForm.addExperienceTechs.find((t) => t.masterTechId === tech.id);
        if(find) {
            setOpenModalTechStack(false);
            return;
        }
        const save = {
            masterTechId: tech.id,
            name: tech.name
        }

        setExperienceForm({...experienceForm, addExperienceTechs: [...experienceForm.addExperienceTechs, save]});
        setAddExperienceTechsEdit([...addExperienceTechsEdit, save]);
        setOpenModalTechStack(false);
    }

    const handleRemoveTech = (data) => {
        const values = experienceForm.addExperienceTechs.filter((t) => t.masterTechId !== data.masterTechId);
        setExperienceForm({...experienceForm, addExperienceTechs: values});

        if (id) {
            const valuesEdit = addExperienceTechsEdit.filter((t) => t.masterTechId !== data.masterTechId);
            setAddExperienceTechsEdit(valuesEdit);
        }

        if (data.id) {
            setRemoveExperienceTechsEdit([...removeExperienceTechsEdit, data])
        }
    }
    
    const createExperience = () => {
        setIsLoading(true);
        createExperienceApi(experienceForm, () => {
            navigate('/admin/experience', {replace: true})
        })
    }
    
    const fetchDataDetailExperiences = () => {
        fetchExperienceByIdApi(id, (data) => {
            setExperienceForm({...experienceForm,
                place: data.place,
                end: data.end,
                description: data.description,
                start: data.start,
                company: data.company,
                position: data.position,
                workingType: data.workingType,
                addExperienceTechs: data.experienceTechs
            })
        })
    }
    
    const updateExperience = () => {
        setIsLoading(true);
        updateExperienceApi(id, {
            position: experienceForm.position,
            company: experienceForm.company,
            place: experienceForm.place,
            workingType: experienceForm.workingType,
            start: experienceForm.start,
            end: experienceForm.end,
            description: experienceForm.description,
            addExperienceTechs: addExperienceTechsEdit,
            removeExperienceTechs: removeExperienceTechsEdit
        }, () => {
            navigate('/admin/experience', {replace: true})
        })
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!id) {
            // console.log(experienceForm)
            createExperience()
        } else {
            updateExperience()
        }
    }
    
    const handleOnChangeInput = (e) => {
        const {name, value} = e.target;
        setExperienceForm({...experienceForm, [name]: value});
    }
    
    const handleService = {
        handleAddTech, createExperience, updateExperience, handleOnChangeInput, 
        handleSubmit, handleRemoveTech, fetchDataDetailExperiences
    }
    
    const stateService = {
        isLoading, setIsLoading, openModalTechStack, setOpenModalTechStack,
        addExperienceTechsEdit, setAddExperienceTechsEdit, removeExperienceTechsEdit, setRemoveExperienceTechsEdit,
        experienceForm, setExperienceForm
    }
    
    return {
        handleService, stateService
    }
}
    