import React, {useEffect, useState} from "react";
import {HeaderPage} from "../../components/admin/HeaderPage";
import {AdminWidget} from "../../widget/AdminWidget";
import {LandingData} from "../../utils/data.util";
import {Link} from "react-router-dom";
import {ModalConfirmation} from "../../components/ModalConfirmation";
import {ExperienceAdminService} from "../Services/ExperienceAdminService";
import SpinnerLoading from "../../components/loadings/SpinnerLoading";
import {LoadingComponent} from "../../components/LoadingComponent";
import {MaximumWordLength} from "../../utils/MaximumWordLength";

const breadcrumbs = [
    {
        name: "Home",
        linkUrl: "/admin"
    },
    {
        name: "Experience",
        linkUrl: "/admin/experience"
    }
]
export const ExperienceAdmin = () => {
    // const [openModalDelete, setOpenModalDelete] = useState(false);
    const {stateService, handleService} = ExperienceAdminService();
    const {fetchExperience, handleCloseDeleteModal, handleDelete, handleOpenDeleteModal, onChangeSearch} = handleService;
    const {experienceId, experienceList, experienceListFilter, fetchExperienceStatus,
        openModalDelete, setExperienceId, setExperienceList, setExperienceListFilter, setFetchExperienceStatus, setOpenModalDelete,
        isLoading, setIsLoading
    } = stateService
    
    useEffect(() => {
        fetchExperience()
    }, [fetchExperienceStatus, setFetchExperienceStatus])

    useEffect(() => {
        if (experienceList) {
            setExperienceListFilter(experienceList)
        }
    }, [experienceList, setExperienceList])

    const onClickCancel = () => {
        setOpenModalDelete(false);
    }

    return(
        <AdminWidget>
            
            {isLoading && (
                <SpinnerLoading text={'Loading fetch experience data'} />
            )}
            
            <ModalConfirmation
                openModal={openModalDelete}
                setOpenModal={setOpenModalDelete}
                onClickYes={handleDelete}
                onClickCancel={handleCloseDeleteModal}
                title={'Are you sure want delete this experience ?'} />

            <HeaderPage linkList={breadcrumbs} tite={'Experience'} />

            <div className={'flex flex-col lg:flex-row md:flex-row sm:flex-row justify-between'}>
                <Link to={'/admin/experience/add'}
                      className={'btn bg-green-700 hover:bg-green-900 text-white'}
                >Add Experience</Link>
                <input onChange={onChangeSearch} type="text" placeholder="Search" className="input input-bordered w-full max-w-xs" />
            </div>

            <div className={'bg-white rounded-xl'}>
                {(experienceListFilter && experienceListFilter.length === 0) && (
                    <div className="overflow-x-auto">
                        <table className="table table-zebra">
                            {/* head */}
                            <thead>
                            <tr>
                                <th>No</th>
                                <th>Position</th>
                                <th>Company</th>
                                <th>Type</th>
                                <th>Start</th>
                                <th>End</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                        </table>
                        <div className={'text-center p-3 font-semibold'}>
                            No Data
                        </div>
                    </div>
                ) }

                {!experienceListFilter && (
                    <LoadingComponent message={'Loading experiences data'} />
                )}

                {(experienceListFilter && experienceListFilter.length > 0) && (
                    <div className="overflow-x-auto">
                        <table className="table table-zebra">
                            {/* head */}
                            <thead>
                            <tr>
                                <th>No</th>
                                <th>Position</th>
                                <th>Company</th>
                                <th>Type</th>
                                <th>Start</th>
                                <th>End</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {/* row 1 */}
                            {experienceListFilter.map((data, index) => (
                                <tr key={index}>
                                    <th>{index + 1}</th>
                                    <td className={'font-semibold'}>
                                        {MaximumWordLength(data.position, 35)}
                                    </td>
                                    <td>{data.company}</td>
                                    <td>{data.workingType}</td>
                                    <td>{data.start}</td>
                                    <td>{data.end}</td>
                                    <td>
                                        <div className={'flex gap-1'}>
                                            <Link to={'/admin/experience/'+ data.id} className={'btn btn-sm bg-green-600 text-white hover:bg-green-800'}>Edit</Link>
                                            <button className={'btn btn-sm bg-red-600 text-white hover:bg-red-800'}
                                                    onClick={() => {
                                                        setOpenModalDelete(true);
                                                        setExperienceId(data.id)
                                                    }}>Delete</button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            
                            </tbody>
                        </table>
                    </div>
                )}
                
            </div>
        </AdminWidget>
    )
}