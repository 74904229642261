import {Button, Checkbox, Label, Modal, TextInput} from "flowbite-react";
import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../Context/GlobalContext";
import {toast} from "react-hot-toast";

export const ModalFormSocialMedia = ({openModal, onCloseModal, id, setFetchStatus}) => {
    const title = id === null ? 'Add Social Media' : 'Edit Social Media';
    const { functionContext } = useContext(GlobalContext);
    const {fetchSocialMediaByIdApi, uploadPhotoApi, updateSocialMediaApi, createSocialMediaApi} = functionContext;
    const [selectedFile, setSelectedFile] = useState();
    const [socialMediaForm, setSocialMediaForm] = useState({
        name: "",
        publicPhotoId: "",
        iconUrl: "",
        linkUrl: ""
    });
    const [socialMediaId, setSocialMediaId] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const onChangeInputText = (e) => {
        const {value, name} = e.target;
        setSocialMediaForm({...socialMediaForm, [name]: value})
    }

    const onChangeSelectFile = (e) => {
        setSelectedFile(e.target.files[0])
    }

    const handleSubmit = () => {
        setIsLoading(true);
        if (id) {
            updateSocialMedia()
        } else {
            createNewSocialMedia()
        }
    }

    const reset = () => {
        setSocialMediaId(null);
        setSocialMediaForm({...socialMediaForm, name: "", iconUrl:"", publicPhotoId:""});
        setSelectedFile(null);
        onCloseModal()
    }

    const fetchSocialMediaDetail = (id) => {
        if (id) {
            fetchSocialMediaByIdApi(id, (data) => {
                setSocialMediaId(id);
                setSocialMediaForm({
                    name: data.name,
                    publicPhotoId: data.publicPhotoId,
                    iconUrl: data.iconUrl,
                    linkUrl: data.linkUrl
                })
                console.log(socialMediaForm);
            })
        }
    }

    const updateSocialMedia = () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append("image", selectedFile);

            uploadPhotoApi(formData, (data) => {
                updateSocialMediaApi(socialMediaId, {
                    name: socialMediaForm.name,
                    iconUrl: data.photoUrl,
                    publicPhotoId: data.publicId,
                    linkUrl: socialMediaForm.linkUrl
                }, (message) => {
                    setFetchStatus(false);
                    reset();
                }, () => {
                    setIsLoading(false);
                })
            });

        } else {
            updateSocialMediaApi(socialMediaId, socialMediaForm,
                (message) => {
                    setFetchStatus(false);
                    reset();
                },
                () => {
                    setIsLoading(false);
                })
        }
    }

    const createNewSocialMedia = () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append("image", selectedFile);
            uploadPhotoApi(formData, (data) => {
                setSocialMediaForm({...socialMediaForm, iconUrl: data.photoUrl, publicPhotoId: data.publicId});
                createSocialMediaApi({
                    name: socialMediaForm.name,
                    iconUrl: data.photoUrl,
                    publicPhotoId: data.publicId,
                    linkUrl: socialMediaForm.linkUrl
                }, () => {
                    setFetchStatus(false);
                    reset();
                }, () => {
                    setIsLoading(false)
                })
            })
        } else {
            toast.error(`Please input photo icon!`);
            setIsLoading(false);
        }
    }

    const handleOnClose = () => {
        setSocialMediaForm({name: '', iconUrl: '', publicPhotoId:'', linkUrl: ''})
        onCloseModal();
    }

    useEffect(() => {
        if(id) {
            fetchSocialMediaDetail(id)
        } else {
            setSocialMediaForm({...socialMediaForm, name: ""})
        }
    },[id]);

    return(
        <Modal show={openModal} size="md" onClose={handleOnClose} popup>
            <Modal.Header />
            <Modal.Body>
                <div className="space-y-6">
                    <h3 className="text-xl text-center font-medium text-gray-900 dark:text-white">{title}</h3>

                    <div className="col-span-full">
                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                            Name
                        </label>
                        <div className="mt-2">
                            <input
                                onChange={onChangeInputText}
                                value={socialMediaForm.name}
                                type="text"
                                name="name"
                                id="name"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div className="col-span-full">
                        <label htmlFor="linkUrl" className="block text-sm font-medium leading-6 text-gray-900">
                            Link
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                onChange={onChangeInputText}
                                value={socialMediaForm.linkUrl}
                                name="linkUrl"
                                id="linkUrl"
                                autoComplete="address-level1"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div className="col-span-full">
                        <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                            Icon
                        </label>
                        <div className="mt-1">
                            <input type="file" onChange={onChangeSelectFile} className="file-input file-input-bordered file-input-primary w-full max-w-sm mt-2" />
                        </div>
                    </div>

                    <div className="w-full flex gap-3">
                        <Button color='blue' onClick={handleSubmit}>Save</Button>
                        <Button color='gray' onClick={handleOnClose}>Cancel</Button>
                        
                    </div>
                    
                </div>
            </Modal.Body>
        </Modal>
    )
}