import React, {useEffect} from "react";
import {HeaderPage} from "../../components/admin/HeaderPage";
import {AdminWidget} from "../../widget/AdminWidget";
import {Link} from "react-router-dom";
import {ModalConfirmation} from "../../components/ModalConfirmation";
import {EducationAdminService} from "../Services/EducationAdminService";
import SpinnerLoading from "../../components/loadings/SpinnerLoading";
import {LoadingComponent} from "../../components/LoadingComponent";
import {MaximumWordLength} from "../../utils/MaximumWordLength";

const breadcrumbs = [
    {
        name: "Home",
        linkUrl: "/admin"
    },
    {
        name: "Education",
        linkUrl: "/admin/portfolio"
    }
]

export const EducationAdmin = () => {
    const {stateService, handleService} = EducationAdminService();
    const {fetchEducationStatus, setFetchEducationStatus, educationList, setEducationList,
        educationListFilter, setEducationListFilter, isLoading, setIsLoading,
        openModalDelete, setOpenModalDelete
    } = stateService;
    
    const {fetchEducations, handleDelete, handleOpenDeleteModal, handleCloseDeleteModal, onChangeSearch} = handleService;
    
    useEffect(() => {
        fetchEducations()
    }, [fetchEducationStatus, setFetchEducationStatus])
    
    useEffect(() => {
        if (educationList) {
            setEducationListFilter(educationList)
        }
    }, [educationList, setEducationList])
    
    return(
        <AdminWidget>
            
            {isLoading && (
                <SpinnerLoading text={'Loading fetch education data'} />
            )}
            
            <ModalConfirmation
                openModal={openModalDelete}
                setOpenModal={setOpenModalDelete}
                onClickYes={handleDelete}
                onClickCancel={handleCloseDeleteModal}
                title={'Are you sure want delete this education ?'} />
            
            <HeaderPage linkList={breadcrumbs} tite={'Education'} />

            <div className={'flex flex-col lg:flex-row md:flex-row sm:flex-row justify-between'}>
                <Link to={'/admin/education/add'}
                    className={'btn bg-green-700 hover:bg-green-900 text-white'}>
                    Add Portfolio
                </Link>
                <input onChange={onChangeSearch} type="text" placeholder="Search subject" className="input input-bordered w-full max-w-xs" />
            </div>

            <div className={'bg-white rounded-xl'}>
                {(educationListFilter && educationListFilter.length === 0)  && (
                    <div className="overflow-x-auto">
                        <table className="table table-zebra">
                            {/* head */}
                            <thead>
                            <tr>
                                <th>No</th>
                                <th>Subject</th>
                                <th>Institution</th>
                                <th>Description</th>
                                <th>Start</th>
                                <th>End</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                        </table>
                        <div className={'text-center p-3 font-semibold'}>
                            No Data
                        </div>
                    </div>
                )}

                {!educationList && (
                    <LoadingComponent message={'Loading educations data'} />
                )}

                {(educationListFilter && educationListFilter.length > 0) && (
                    <div className="overflow-x-auto">
                        <table className="table table-zebra">
                            {/* head */}
                            <thead>
                            <tr>
                                <th>No</th>
                                <th>Subject</th>
                                <th>Institution</th>
                                <th>Description</th>
                                <th>Start</th>
                                <th>End</th>
                                <th>Type</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {educationListFilter.map((data, index) => (
                                <tr key={index}>
                                    <th>{index + 1}</th>
                                    <td>
                                        {data.subject}
                                    </td>
                                    <td>{data.institution}</td>
                                    <td>{MaximumWordLength(data.description, 50)}</td>
                                    <td>{data.start}</td>
                                    <td>{data.end}</td>
                                    <td>{data.isFormal ? 'Formal' : 'Non Formal' }</td>
                                    <td>
                                        <div className={'flex gap-1'}>
                                            <Link to={'/admin/education/' + data.id}
                                                  className={'btn btn-sm bg-green-600 text-white hover:bg-green-800'}>Edit</Link>
                                            <button className={'btn btn-sm bg-red-600 text-white hover:bg-red-800'}
                                                    onClick={() => handleOpenDeleteModal(data.id)}>Delete</button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </AdminWidget>
    )
}