import {useNavigate, useParams} from "react-router-dom";
import {useContext, useState} from "react";
import {GlobalContext} from "../../Context/GlobalContext";

export const EducationFormAdminService = () => {
    const {id} = useParams();
    const {functionContext, stateContext} = useContext(GlobalContext);
    const {createEducationApi, fetchEducationByIdApi, updateEducationApi} = functionContext;
    const navigate = useNavigate();
    
    const [errorMessage, setErrorMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [educationForm, setEducationForm] = useState({
        subject: "",
        institution: "",
        description: "",
        start: "",
        end: "",
        isFormal: null
    })
    const title = id === undefined ? "Add Education" : "Edit Education"; 
    
    const fetchEducationDetail = () => {
        if (id) {
            fetchEducationByIdApi(id, (data) => {
                setEducationForm({
                    subject: data.subject,
                    institution: data.institution,
                    start: data.start,
                    end: data.end,
                    description: data.description,
                    isFormal: data.isFormal
                })
            })
        }
    }
    
    const handleChangeInputEducation = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setEducationForm({...educationForm, [name]: name === 'isFormal' ? value === 'true' : value})
        setErrorMessage(null)
    }
    
    const submit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            if (!id) {
                createEducationApi(educationForm, () => {
                    navigate('/admin/education', {replace: true});
                })
            }
            else {
                updateEducationApi(id, educationForm, () => {
                    navigate('/admin/education', {replace: true});
                })
            }
        } catch (e) {
            
        } finally {
            setIsLoading(false)
        }
    }
    
    const functionService = {submit, handleChangeInputEducation, fetchEducationDetail}
    const stateService = {educationForm, setEducationForm, title, 
        errorMessage, setErrorMessage, isLoading, setIsLoading}
    
    return{
        functionService, stateService
    }
}