import './custom.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
// import App from './App';
import RouterApp from "./routes/RouterApp";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <React.StrictMode>
        <RouterApp />
        {/*<App />*/}
    </React.StrictMode>
)

