export const AdminMenus = [
    {
        name: "Landing Page",
        linkUrl: "/"
    },
    {
        name: "Home",
        linkUrl: "/admin"
    },
    {
        name: "Portfolio",
        linkUrl: "/admin/portfolio"
    },
    {
        name: "Education",
        linkUrl: "/admin/education"
    },
    {
        name: "Experience",
        linkUrl: "/admin/experience"
    },
    {
        name: "Master Tech",
        linkUrl: "/admin/masterTech"
    },
    {
        name: "Social Media",
        linkUrl: "/admin/socialMedia"
    },
    {
        name: "Skill",
        linkUrl: "/admin/techStack"
    }
]