import {Modal} from "flowbite-react";
import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../Context/GlobalContext";
import {toast} from "react-hot-toast";
import SpinnerLoading from "./loadings/SpinnerLoading";

export const ModalFormMasterTech = ({openModal, onCloseModal, id, setFetchStatus}) => {
    const title = id === null ? 'Add Master Tech' : 'Edit Master Tech';
    const { functionContext } = useContext(GlobalContext);
    const {getMasterTechById, uploadPhotoApi, updateMasterTechApi, createMasterTechApi} = functionContext;
    const [selectedFile, setSelectedFile] = useState();
    const [masterTechForm, setMasterTechForm] = useState({
        name: "",
        publicPhotoId: "",
        iconUrl: ""
    });
    const [masterTechId, setMasterTechId] = useState();
    const [isLoading, setIsLoading] = useState(false);
    
    const onChangeInputText = (e) => {
        const {value} = e.target;
        setMasterTechForm({...masterTechForm, name: value})
    }
    
    const onChangeSelectFile = (e) => {
        setSelectedFile(e.target.files[0])
    }
    
    const handleSubmit = () => {
        setIsLoading(true);
        if (id) {
            updateMasterTech()
        } else {
            createNewMasterTech()
        }
    }
    
    const reset = () => {
        setMasterTechId(null);
        setMasterTechForm({...masterTechForm, name: "", iconUrl:"", publicPhotoId:""});
        onCloseModal()
    }
    
    const fetchMasterTechDetail = (id) => {
        if (id) {
            getMasterTechById(id, (data) => {
                setMasterTechId(id);
                setMasterTechForm({
                    name: data.name,
                    publicPhotoId: data.publicPhotoId,
                    iconUrl: data.iconUrl
                })
                console.log(masterTechForm);
            })
        }
    }

    const updateMasterTech = () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append("image", selectedFile);

            uploadPhotoApi(formData, (data) => {
                updateMasterTechApi(masterTechId, {
                    name: masterTechForm.name,
                    iconUrl: data.photoUrl,
                    publicPhotoId: data.publicId
                }, (message) => {
                    toast.success(message);
                    setFetchStatus(false);
                    reset();
                }, () => {
                    setIsLoading(false);
                })
            });

        } else {
            updateMasterTechApi(masterTechId, masterTechForm,
                (message) => {
                    toast.success(message);
                    setFetchStatus(false);
                    reset();
                },
                () => {
                    setIsLoading(false);
                })
        }
    }

    const createNewMasterTech = () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append("image", selectedFile);
            uploadPhotoApi(formData, (data) => {
                setMasterTechForm({...masterTechForm, iconUrl: data.photoUrl, publicPhotoId: data.publicId});
                createMasterTechApi({
                    name: masterTechForm.name,
                    iconUrl: data.photoUrl,
                    publicPhotoId: data.publicId
                }, () => {
                    setFetchStatus(false);
                    reset();
                }, () => {
                    setIsLoading(false)
                })
            })
        } else {
            toast.error(`Please input photo icon!`);
            setIsLoading(false);
        }
    }
    
    const handleOnClose = () => {
        setMasterTechForm({name: '', iconUrl: '', publicPhotoId:''})
        onCloseModal();
    }
    
    useEffect(() => {
        if(id) {
            fetchMasterTechDetail(id) 
        } else {
            setMasterTechForm({...masterTechForm, name: ""})
        }
    },[id]);
    
    return(
        <Modal show={openModal} size="md" onClose={handleOnClose} popup>
            {isLoading && (
                <SpinnerLoading text={'Saving data'} />
            )}
            <Modal.Header />
            <Modal.Body>
                <div className="space-y-6">
                    <h3 className="text-xl text-center font-medium text-gray-900 dark:text-white">{title}</h3>
                    
                    <div className="col-span-full">
                        <label htmlFor="name1" className="block text-sm font-medium leading-6 text-gray-900">
                            Name
                        </label>
                        <div className="mt-2">
                            <input
                                value={masterTechForm.name}
                                onChange={onChangeInputText}
                                type="text"
                                name="name"
                                id="name1"
                                // autoComplete="address-level1"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div className="col-span-full">
                        <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                            Icon
                        </label>
                        <div className="mt-1">
                            <input 
                                onChange={onChangeSelectFile} 
                                type="file" className="file-input file-input-bordered file-input-primary w-full max-w-sm mt-2" />
                        </div>
                    </div>
                    
                    <div className="w-full">
                        <button type={"button"} onClick={handleSubmit} className={'btn btn-success btn-sm'}>Save</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}