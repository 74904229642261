import {Button, Modal} from "flowbite-react";
import {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../../Context/GlobalContext";

export const ModalAddTechStack = ({openModal, onCloseModal, handleAdd}) => {
    const {functionContext, stateContext} = useContext(GlobalContext);
    const {masterTechList} = stateContext;
    const {fetchMasterTechListApi} = functionContext;
    const [selectedTech, setSelectedTech] = useState(null);
    
    const handleChange = (e) => {
        setSelectedTech(e.target.value);
        // console.log(e.target.value);
    }
    
    useEffect(() => {
        fetchMasterTechListApi();
    },[])

    const handleAddClick = () => {
        if (selectedTech) {
            const selectedTechObj = masterTechList.find(tech => tech.id === selectedTech);
            const value = {
                id: selectedTechObj.id,
                name: selectedTechObj.name
            }
            handleAdd(value);
            
            setSelectedTech(null);
        }
    }
    
    return(
        <Modal show={openModal} size="md" onClose={onCloseModal} popup>
            <Modal.Header />
            <Modal.Body>
                <div className="space-y-6">
                    <h3 className="text-xl text-center font-medium text-gray-900 dark:text-white">Add Tech Stack</h3>
                    <select className="select select-primary w-full"
                            defaultValue={null}
                            onChange={handleChange}>
                        <option disabled selected>Select Tech Stack</option>
                        {masterTechList?.map(({id, name}) => (
                            <option key={id} value={id}>{name}</option>
                        ))}
                    </select>
                    <div className="w-full">
                        <Button onClick={handleAddClick}>Add</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}