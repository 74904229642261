import {createContext, useState} from "react";
import {axiosConfig} from "../configs/axiosConfig";
import {toast, Toaster} from "react-hot-toast";

export const GlobalContext = createContext();
export const GlobalProvider = ({children}) => {
    const [portfolioList, setPortfolioList] = useState(null);
    const [portfolioDetail, setPortfolioDetail] = useState(null);
    const [masterTechList, setMasterTechList] = useState(null);
    const [techTypeList, setTechTypeList] = useState(null);
    
    // ++++++++++++ Portfolio +++++++++++++++++++++
    const addPortfolioApi = (data, callFinally) => {
        axiosConfig.post(`portfolio`, data)
            .then(({data}) => {
                toast.success(data.message);
            })
            .catch((error) => {
                toast.error('Failed save portfolio')
                console.log(error.response.data)
            })
            .finally(() => {
                callFinally()
            })
    }
    
    const fetchPortfolioList = () => {
        axiosConfig.get('portfolio')
            .then(({data}) => {
                setPortfolioList(data.data);
                // console.log(portfolioList)
            })
            .catch((error) => {
                console.log('Portfolio list error: ' + error)
            })
    }
    
    const fetchPortfolioByIdApi = (id, callSuccess) => {
        axiosConfig.get(`portfolio/${id}`)
            .then(({data}) => {
                setPortfolioDetail(data.data);
                callSuccess(data.data)
            })
            .catch((error) => {
                console.log(error);
                toast.error("Failed when fetch detail data");
            })
    }
    
    const updatePortfolioApi = (id, data, callSuccess) => {
        axiosConfig.put(`portfolio/${id}`, data)
            .then(({data}) => {
                toast.success(data.message);
                callSuccess()
            })
            .catch((error) => {
                console.log('Edit Data ' + error);
                toast.error("Failed when updating data")
            })
    }
    
    const deletePortfolioApi = (id, callFinally) => {
        axiosConfig.delete(`portfolio/${id}`)
            .then(({data}) => {
                toast.success(data.message);
            })
            .catch((error) => {
                toast.error(!error.response.data.message ? error : error.response.data.message);
                console.log(!error.response.data.message ? error : error.response.data);
            })
            .finally(() => {
                callFinally()
            })
    }
    
    // Master Tech
    const fetchMasterTechListApi = () => {
        axiosConfig.get('masterTech')
            .then(({data}) => {
                setMasterTechList(data.data);
                // console.log(masterTechList)
            })
            .catch((error) => {
                toast.error("Failed to fetch master tech")
                console.log(!error.response.data.message ? error : error.response.data.message)
            })
    }
    
    const updateMasterTechApi = (id, data, callSuccess, callFinally) => {
        axiosConfig.put(`masterTech/${id}`, data)
            .then(({data}) => {
                callSuccess(data.message)
            })
            .catch((error) => {
                console.log(error);
                toast.error("Failed to update master tech");
            })
            .finally(() => {
                callFinally()
            })
    }
    
    const deleteMasterTechApi = (id, callFinally) => {
        axiosConfig.delete(`masterTech/${id}`)
            .then(({data}) => {
                toast.success(data.message)
            })
            .catch((error) => {
                console.log(error);
                toast.error("Failed delete master tech");
            })
            .finally(() => {
                callFinally()
            })
    }
    
    const getMasterTechById = (id, callSuccess) => {
        axiosConfig.get(`masterTech/${id}`)
            .then(({data}) => {
                callSuccess(data.data);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Failed create master tech");
            })
    }
    
    const createMasterTechApi = (data, callSuccess, callFinally) => {
        axiosConfig.post(`masterTech`,data )
            .then(({data}) => {
                toast.success(data.message);
                callSuccess();
            })
            .catch((error) => {
                console.log(error);
                toast.error("Failed create master tech");
            })
            .finally(() => {
                callFinally()
            })
    }
    
    // Photo handler
    const uploadPhotoApi = (formData:FormData, callSuccessFunction) => {
        axiosConfig.post(`photo`, formData,{
            headers: {"Content-Type: multipart/form-data":undefined}
        })
            .then(({data}) => {
                toast.success(data.message);
                callSuccessFunction(data.data)
            })
            .catch((error) => {
                console.log(error);
                toast.error("Failed upload photo")
            })
    }
    
    const deletePhotoApi = (publicIdPhoto, callSuccessFunction) => {
        axiosConfig.put(`photo`, { publicId: publicIdPhoto })
            .then(({data}) => {
                // toast.success(data.message);
                // console.log(data);
                callSuccessFunction(data)
            })
            .catch((error) => {
                console.log(error);
                toast.error("Failed delete photo")
            })
    }
    
    const deletePhotoBatchApi = (publicIds, callSuccessFunction) => {
        axiosConfig.put(`photo/batch`, publicIds)
            .then(() => {
                // toast.success(data.message);
                // console.log(data);
                callSuccessFunction();
            })
            .catch((error) => {
                console.log(error);
                toast.error("Failed delete photo")
            })
    }
    
    // Education
    const fetchAllEducationApi = (callSuccess) => {
        axiosConfig.get(`education`)
            .then(({data}) => {
                callSuccess(data.data)
            })
            .catch((error) => {
                toast.error("Failed fetch education data");
                console.log(error);
            })
    }
    
    const fetchEducationByIdApi = (id, callSuccess) => {
        axiosConfig.get(`education/${id}`)
            .then(({data}) => {
                callSuccess(data.data);
            })
            .catch((error) => {
                toast.error("Failed fetch education detail");
                console.log(error);
            })
    }
    
    const createEducationApi = (data, callSuccess) => {
        axiosConfig.post('education', data)
            .then(({data}) => {
                toast.success(data.message)
                callSuccess()
            })
            .catch((error) => {
                toast.error(error.response.message ?? error.message);
                console.log(error)
            })
    }
    
    const updateEducationApi = (id, data, callSuccess) => {
        axiosConfig.put(`education/${id}`, data)
            .then(() => {
                callSuccess();
            })
            .catch((error) => {
                toast.error(error.response.data.message ?? error.message );
                console.log(error);
            })
    }
    
    const deleteEducationApi = (id, callFinally) => {
        axiosConfig.delete(`education/${id}`)
            .then(({data}) => {
                toast.success(data.message);
            })
            .catch((error) => {
                toast.error(error.response.data.message ?? error.message)
                console.log(error)
            })
            .finally(() => {
                callFinally()
            })
    }
    
    //Experience
    const fetchAllExperienceApi = (callSuccess) => {
        axiosConfig.get(`experience`)
            .then(({data}) => {
                callSuccess(data.data)
            })
            .catch((error) => {
                toast.error("Failed fetch experience data");
                console.log(error);
            })
    }

    const deleteExperienceApi = (id, callFinally) => {
        axiosConfig.delete(`experience/${id}`)
            .then(({data}) => {
                toast.success(data.message);
            })
            .catch((error) => {
                toast.error(error.response.data.message ?? error.message)
                console.log(error)
            })
            .finally(() => {
                callFinally()
            })
    }

    const fetchExperienceByIdApi = (id, callSuccess) => {
        axiosConfig.get(`experience/${id}`)
            .then(({data}) => {
                callSuccess(data.data);
            })
            .catch((error) => {
                toast.error("Failed fetch experience detail");
                console.log(error);
            })
    }
    
    const createExperienceApi = (data, callSuccess) => {
        axiosConfig.post('experience', data)
            .then(({data}) => {
                toast.success(data.message)
                callSuccess()
            })
            .catch((error) => {
                toast.error(error.response.message ?? error.message);
                console.log(error)
            })
    }
    
    const updateExperienceApi = (id, data, callSuccess) => {
        axiosConfig.put(`experience/${id}`, data)
        .then(({data}) => {
            toast.success(data.message);
                callSuccess();
            })
            .catch((error) => {
                toast.error(error.response.data.message ?? error.message );
                console.log(error);
            })
    }
    
    // Social Media
    const fetchAllSocialMediaApi = (callSuccess) => {
        axiosConfig.get(`socialMedia`)
            .then(({data}) => {
                callSuccess(data.data)
            })
            .catch((error) => {
                toast.error("Failed fetch socialMedia data");
                console.log(error);
            })
    }
    
    const deleteSocialMediaApi = (id, callFinally) => {
        axiosConfig.delete(`socialMedia/${id}`)
            .then(({data}) => {
                toast.success(data.message);
            })
            .catch((error) => {
                toast.error(error.response.data.message ?? error.message)
                console.log(error)
            })
            .finally(() => {
                callFinally()
            })
    }
    
    const fetchSocialMediaByIdApi = (id, callSuccess) => {
        axiosConfig.get(`socialMedia/${id}`)
            .then(({data}) => {
                callSuccess(data.data);
            })
            .catch((error) => {
                toast.error("Failed fetch socialMedia detail");
                console.log(error);
            })
    }
    
    const createSocialMediaApi = (data, callSuccess) => {
        axiosConfig.post('socialMedia', data)
            .then(({data}) => {
                toast.success(data.message)
                callSuccess()
            })
            .catch((error) => {
                toast.error(error.response.message ?? error.message);
                console.log(error)
            })
    }
    
    const updateSocialMediaApi = (id, data, callSuccess) => {
        axiosConfig.put(`socialMedia/${id}`, data)
            .then(({data}) => {
                toast.success(data.message);
                callSuccess();
            })
            .catch((error) => {
                toast.error(error.response.data.message ?? error.message );
                console.log(error);
            })
    }
    
    // Tech Stack
    const fetchAllSkillApi = (callSuccess) => {
        axiosConfig.get(`skill`)
            .then(({data}) => {
                callSuccess(data.data)
            })
            .catch((error) => {
                toast.error("Failed fetch skill data");
                console.log(error);
            })
    }

    const deleteSkillApi = (id, callFinally) => {
        axiosConfig.delete(`skill/${id}`)
            .then(({data}) => {
                toast.success(data.message);
            })
            .catch((error) => {
                toast.error(error.response.data.message ?? error.message)
                console.log(error)
            })
            .finally(() => {
                callFinally()
            })
    }
    
    const fetchSkillByIdApi = (id, callSuccess) => {
        axiosConfig.get(`skill/${id}`)
            .then(({data}) => {
                callSuccess(data.data);
            })
            .catch((error) => {
                toast.error("Failed fetch skill detail");
                console.log(error);
            })
    }
    
    const createSkillApi = (data, callSuccess) => {
        axiosConfig.post('skill', data)
            .then(({data}) => {
                toast.success(data.message)
                callSuccess()
            })
            .catch((error) => {
                toast.error(error.response.data.message ?? error.message);
                console.log(error)
            })
    }

    const updateSkillApi = (id, data, callSuccess) => {
        axiosConfig.put(`skill/${id}`, data)
            .then(({data}) => {
                toast.success(data.message);
                callSuccess();
            })
            .catch((error) => {
                toast.error(error.response.data.message ?? error.message );
                console.log(error);
            })
    }
    
    const fetchAllTechTypeApi = () => {
        axiosConfig.get(`techType`)
            .then(({data}) => {
                setTechTypeList(data.data);
            })
            .catch((error) => {
                console.log(error);
                toast.error('Failed when fetch Tech Type')
            })
    }

    const fetchProfileData = (callSuccess, callFinally) => {
        axiosConfig.get('profile')
            .then(({data}) => {
                callSuccess(data.data);
            })
            .then((error) => {
                toast.error(error.response.data.message);
                console.log(error)
            })
            .finally(() => {
                callFinally(false);
            })
    }
    
    // profile
    const updateProfileApi = (data, callSuccess, callFinally) => {
        axiosConfig.put(`profile`, data)
            .then(({data}) => {
                toast.success(data.message);
                callSuccess();
            })
            .catch((error) => {
                toast.error('Error when updating data');
                console.log(error)
            })
            .finally(() => {
                callFinally()
            })
    }

    const uploadFileApi = (formData:FormData, callSuccessFunction) => {
        axiosConfig.post(`file/upload`, formData,{
            headers: {"Content-Type: multipart/form-data":undefined}
        })
            .then(({data}) => {
                toast.success(data.message);
                callSuccessFunction(data.data)
            })
            .catch((error) => {
                console.log(error);
                toast.error("Failed upload file")
            })
    }
    
    const functionContext = 
        {
            fetchPortfolioList, deletePortfolioApi, fetchMasterTechListApi, 
            deletePhotoApi, uploadPhotoApi, deletePhotoBatchApi, addPortfolioApi, fetchPortfolioByIdApi,
            updatePortfolioApi, fetchEducationByIdApi, fetchAllEducationApi, updateEducationApi,
            deleteEducationApi, createEducationApi, updateMasterTechApi, deleteMasterTechApi, getMasterTechById, createMasterTechApi,
            fetchAllExperienceApi, deleteExperienceApi, fetchExperienceByIdApi, createExperienceApi, updateExperienceApi,
            fetchAllSocialMediaApi, deleteSocialMediaApi, fetchSocialMediaByIdApi, createSocialMediaApi, updateSocialMediaApi,
            fetchAllSkillApi, deleteSkillApi, fetchSkillByIdApi, createSkillApi, updateSkillApi, fetchAllTechTypeApi, 
            fetchProfileData, updateProfileApi, uploadFileApi
        }
        
    const stateContext = {
        portfolioList, setPortfolioList, masterTechList, setMasterTechList, 
        portfolioDetail, setPortfolioDetail, techTypeList, setTechTypeList
    };
    
    return(
        <GlobalContext.Provider value={{functionContext, stateContext}}>
            <Toaster />
            {children}
        </GlobalContext.Provider>
    )
}