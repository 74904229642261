import backIcon from "../../../resources/arrow left.png";
import replyIcon from "../../../resources/reply.png";
import deleteIcon from "../../../resources/delete.png";

import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ModalReplyInbox} from "./ModalReplyInbox";
import {ModalConfirmation} from "../../ModalConfirmation";
import {axiosConfig} from "../../../configs/axiosConfig";
import {toast} from "react-hot-toast";
import SpinnerLoading from "../../loadings/SpinnerLoading";
import moment from "moment";
export const InboxDetailComponent = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [openModalReply, setOpenModalReply] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [inboxDetail, setInboxDetail] = useState();
    const [isLoading, setIsLoading] = useState(true);
    
    const fetchInboxDetail = () => {
        axiosConfig.get(`inbox/${id}`)
            .then(({data}) => {
                setInboxDetail(data.data);
                if (!data.data.isRead) {
                    changeIsRead()
                }
            })
            .catch((data) => {
                toast.error(data.response.data.message);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }
    
    const changeIsRead = () => {
        axiosConfig.put(`inbox/read/${id}`)
            .then(() => {
                
            })
            .catch((error) => {
                toast.error('Error update read')
                console.log(error)
            })
    }
    
    const deleteInboxDetail = () => {
        axiosConfig.delete(`inbox/${id}`)
            .then(({data}) => {
                toast.success(data.message);
                navigate('/admin/inbox')
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                console.log(error)
            })
    }
    
    useEffect(() => {
        fetchInboxDetail()
    }, []);
    
    const handleCloseDelete = () => {
        deleteInboxDetail()
        setOpenModalDelete(false);
    }
    
    return(
        <React.Fragment>
            {isLoading && (
                <SpinnerLoading text={'Loading fetch inbox detail'} textColor={'white'} />
            )}
            <ModalConfirmation 
                openModal={openModalDelete} 
                setOpenModal={setOpenModalDelete}
                title={'Are you sure want delete this message ?'} 
                onClickYes={handleCloseDelete} onClickCancel={handleCloseDelete} />
            <ModalReplyInbox 
                openModal={openModalReply} 
                onCloseModal={() => setOpenModalReply(false)}
                email={inboxDetail?.email}
            />
            <div className={'flex flex-col gap-3'}>
                <div className={'flex'}>
                    <Link to={'/admin/inbox'} className={'rounded-full hover:bg-slate-200 w-[48px] h-[48px] mr-5'}>
                        <img src={backIcon} alt={'back'} width={'48px'}/>
                    </Link>
    
                    <button
                        onClick={() => setOpenModalReply(true)}
                        className={'rounded-full hover:bg-slate-200 w-[48px] h-[48px]'}>
                        <img src={replyIcon} alt={'back'} width={'48px'}/>
                    </button>
    
                    <button
                        onClick={() => setOpenModalDelete(true)}
                        className={'rounded-full hover:bg-slate-200 w-[48px] h-[48px]'}>
                        <img src={deleteIcon} alt={'back'} width={'48px'}/>
                    </button>
                </div>
                <div className={'font-bold text-lg ml-3'}>
                    {inboxDetail?.subject}
                </div>
                
                <div className={'flex justify-between ml-3'}>
                    <div>
                        <span className={'font-semibold'}>{inboxDetail?.name}</span>
                        <p className={'text-slate-500'}> {inboxDetail?.email} </p>
                    </div>
                    <div>
                        {moment(inboxDetail?.received).format('MMMM Do YYYY, h:mm:ss a')}
                    </div>
                </div>
                
                <div className={'ml-3 mt-5'}>
                    {inboxDetail?.message}
                </div>
            </div>
        </React.Fragment>
    )
}