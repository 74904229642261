const TechCard = ({title, techStacks}) => {
    return(
        <div className={'mb-6'}>
            <p className={'text-blue-950 font-semibold text-xl'}>{title}</p>
            <div className={'grid grid-cols-6 gap-4 mt-3 items-center'}>
                {techStacks?.map(({name, iconUrl}) => (
                    <img width={'64px'} src={iconUrl} alt={name} />
                ))}
            </div>
        </div>
    )
}
export default TechCard;