import React from "react";
import TitleProject from "./TitleProject";
import TechPortfolioCard from "./TechPortfolioCard";
import javaIcon from "../../../resources/tech-stacks/java-original.svg";
import csharpIcon from "../../../resources/tech-stacks/c-sharp.png";
import skillIcon from "../../../resources/tech-stacks/skill.png";
import javascriptIcon from "../../../resources/tech-stacks/javascript-original.svg";
import phpIcon from "../../../resources/tech-stacks/php.svg";
import tsIcon from "../../../resources/tech-stacks/Typescript.svg.png";
import netIcon from "../../../resources/tech-stacks/net.png";
import reactIcon from "../../../resources/tech-stacks/react.png";
import expressIcon from "../../../resources/tech-stacks/express.svg";
const techStacks = [
    {
        name: "java",
        iconUrl: javaIcon
    },
    {
        name: "C#",
        iconUrl: csharpIcon
    },
    {
        name: "PHP",
        iconUrl: phpIcon
    },
    {
        name: "JavaScript",
        iconUrl: javascriptIcon
    },
    {
        name: "Typescript",
        iconUrl: tsIcon
    },
    {
        name: "React",
        iconUrl: reactIcon
    },
    {
        name: "net",
        iconUrl: netIcon
    },
    {
        name: "express",
        iconUrl: expressIcon
    }
]
const TechnologiesUsed = ({tags}) => {
    return(
        <React.Fragment>
            <TitleProject text={'Technologies Used'} />
            <TechPortfolioCard techStacks={tags} />
        </React.Fragment>
    )
}
export default TechnologiesUsed