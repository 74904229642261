import React, {useEffect} from "react";
import {HeaderPage} from "../../components/admin/HeaderPage";
import {AdminWidget} from "../../widget/AdminWidget";
import {ModalConfirmation} from "../../components/ModalConfirmation";
import {ModalFormMasterTech} from "../../components/ModalFormMasterTech";
import {MasterTechService} from "../Services/MasterTechService";
import SpinnerLoading from "../../components/loadings/SpinnerLoading";

const breadcrumbs = [
    {
        name: "Home",
        linkUrl: "/admin"
    },
    {
        name: "Master TechStack",
        linkUrl: "/admin/masterStach"
    }
]
export const MasterTech = () => {
    const {stateService, handleService} = MasterTechService();
    const {
        fetchMasterTechs, onChangeSearch, handleDelete,
        handleCloseDeleteModal, handleCloseFormModal, handleOpenEditForm,
        handleButtonAdd
    } = handleService

    const {
        masterTechId, setMasterTechId, fetchMasterTechStatus, setFetchMasterTechStatus,
        masterTechList, setMasterTechList, masterTechListFilter, setMasterTechListFilter,
        openDeleteMaster, setOpenDeleteMaster, openFormMaster
    } = stateService

    useEffect(() => {
        fetchMasterTechs();
        setFetchMasterTechStatus(true);
    }, [fetchMasterTechStatus, setFetchMasterTechStatus]);

    useEffect(() => {
        if (masterTechList) {
            setMasterTechListFilter(masterTechList);
            // console.log(portfolioList);
        }
    }, [masterTechList, setMasterTechList]);
    
    return(
        <AdminWidget>
            <ModalConfirmation
                openModal={openDeleteMaster}
                setOpenModal={setOpenDeleteMaster}
                onClickYes={handleDelete}
                onClickCancel={handleCloseDeleteModal}
                title={'Are you sure want delete this Tech ?'} />
            
            <ModalFormMasterTech 
                id={masterTechId} 
                openModal={openFormMaster} 
                onCloseModal={handleCloseFormModal}
                setFetchStatus={setFetchMasterTechStatus}
            />

            <HeaderPage linkList={breadcrumbs} tite={'Master Tech'} />

            <div className={'flex flex-col lg:flex-row md:flex-row sm:flex-row justify-between'}>
                <button onClick={handleButtonAdd}
                      className={'btn bg-green-700 hover:bg-green-900 text-white'}
                >Add Tech</button>
                <input type="text" onChange={onChangeSearch} placeholder="Search" className="input input-bordered w-full max-w-xs" />
            </div>

            <div className={'bg-white rounded-xl'}>
                {(masterTechListFilter && masterTechListFilter.length === 0) && (
                    <div className="overflow-x-auto">
                        <table className="table table-zebra">
                            {/* head */}
                            <thead>
                            <tr>
                                <th>No</th>
                                <th>Icon</th>
                                <th>Name</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                        </table>
                        <div className={'text-center p-3 font-semibold'}>
                            No Data
                        </div>
                    </div>
                )}
                {!masterTechListFilter && (
                    <SpinnerLoading text={'Loading data master tech'} />
                )}
                <div className="overflow-x-auto">
                    <table className="table table-zebra">
                        {/* head */}
                        <thead>
                        <tr>
                            <th>No</th>
                            <th>Icon</th>
                            <th>Name</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {/* row 1 */}
                        {masterTechListFilter && (
                            masterTechListFilter.map((data, index) => (
                                <tr key={index}>
                                    <th>{index+1}</th>
                                    <td>
                                        <div className="avatar">
                                            <div className="mask mask-squircle w-12 h-12">
                                                <img src={data.iconUrl} alt="Avatar Tailwind CSS Component" />
                                            </div>
                                        </div>
                                    </td>
                                    <td>{data.name}</td>
                                    <td>
                                        <div className={'flex gap-1'}>
                                            <button
                                                className={'btn btn-sm bg-green-600 text-white hover:bg-green-800'}
                                                onClick={() => {
                                                    handleOpenEditForm(data.id)
                                                }}
                                            >Edit</button>
                                            <button className={'btn btn-sm bg-red-600 text-white hover:bg-red-800'}
                                                    onClick={() => {
                                                        setMasterTechId(data.id)
                                                        setOpenDeleteMaster(true)
                                                    }}>Delete</button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </AdminWidget>
    )
}