import React, {useEffect} from "react";
import {HeaderPage} from "../../components/admin/HeaderPage";
import {AdminWidget} from "../../widget/AdminWidget";
import {Link} from "react-router-dom";
import {ModalConfirmation} from "../../components/ModalConfirmation";
import {MaximumWordLength} from "../../utils/MaximumWordLength";
import {PortfolioAdminService} from "../Services/PortfolioAdminService";
import {LoadingComponent} from "../../components/LoadingComponent";

const breadcrumbs = [
    {
        name: "Home",
        linkUrl: "/admin"
    },
    {
        name: "Portfolio",
        linkUrl: "/admin/portfolio"
    }
]
export const PortfolioAdmin = () => {
    const {handleService, stateService} = PortfolioAdminService();
    const {onClickYes, onClickCancel, fetchPortfolioList, onChangeSearch} = handleService;
    const {portfolioFilter, portfolioList, setPortfolioFilter, fetchStatus, setFetchStatus, portfolioId, 
        setPortfolioId, openModalDelete, setOpenModalDelete, setPortfolioList} = stateService;

    useEffect(() => {
        fetchPortfolioList();
        setFetchStatus(true);
    }, [fetchStatus, setFetchStatus]);     
    
    useEffect(() => {
        if (portfolioList) {
            setPortfolioFilter(portfolioList);
            // console.log(portfolioList);
        }
    }, [portfolioList, setPortfolioList]);
    
    return(
        <AdminWidget>
            <ModalConfirmation 
                openModal={openModalDelete} 
                setOpenModal={setOpenModalDelete}
                onClickYes={onClickYes}
                onClickCancel={onClickCancel}
                title={'Are you sure want delete this portfolio ?'} />
            
            <HeaderPage linkList={breadcrumbs} tite={'Portfolio'} />
            
            <div className={'flex flex-col lg:flex-row md:flex-row sm:flex-row justify-between'}>
                <Link to={'/admin/portfolio/add'} 
                    className={'btn bg-green-700 hover:bg-green-900 text-white'}
                >Add Portfolio</Link>
                <input type="text" placeholder="Search" className="input input-bordered w-full max-w-xs" onChange={onChangeSearch}/>
            </div>
            
            <div className={'bg-white rounded-xl'}>
                {(portfolioFilter && portfolioFilter.length) === 0 && (
                    <div>
                        <table className="table table-zebra table-pin-rows table-pin-cols">
                            {/* head */}
                            <thead>
                            <tr>
                                <th>No</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Link Project</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                        </table>
                        <div className={'text-center p-3 font-semibold'}>
                            No Data
                        </div>
                    </div>
                )}
                {!portfolioFilter && (
                    <LoadingComponent message={'Loading portfolios data'} />
                )}
                {(portfolioFilter && portfolioFilter.length > 0) && (
                    <div className="overflow-x-auto">
                        <table className="table table-zebra table-pin-rows table-pin-cols">
                            {/* head */}
                            <thead>
                            <tr>
                                <th>No</th>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Link Project</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {/* row 1 */}
                            {portfolioFilter?.map((data, index) => (
                                <tr key={data.id}>
                                    <th>{index + 1}</th>
                                    <td>
                                        <div className="flex items-center gap-3">
                                            <div className="avatar">
                                                <div className="mask mask-squircle w-12 h-12">
                                                    <img src={data.photoUrl} alt="Avatar Tailwind CSS Component" />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="font-bold">{data.title}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{MaximumWordLength(data.description, 80)}</td>
                                    {data.link === null ? (
                                            <td>No link</td>
                                        ) :
                                        <td><a className={'text-blue-700 hover:text-blue-950'} href={data.link} target={'_blank'}>Go</a></td>
                                    }
                                    <td>
                                        <div className={'flex gap-1'}>
                                            <Link to={'/admin/portfolio/' + data.id} className={'btn btn-sm bg-green-600 text-white hover:bg-green-800'}>Edit</Link>
                                            <button className={'btn btn-sm bg-red-600 text-white hover:bg-red-800'}
                                                    onClick={() => {
                                                        setPortfolioId(data.id)
                                                        setOpenModalDelete(true)
                                                    }}>Delete</button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
                
            </div>
        </AdminWidget>
    )
}