import {AdminWidget} from "../../widget/AdminWidget";
import {PortfolioFormComponent} from "../../components/admin/PortfolioForm/PortfolioFormComponent";
import {useParams} from "react-router-dom";

export const PortfolioForm = () => {
    return(
        <AdminWidget>
            <PortfolioFormComponent />
        </AdminWidget>
    )
}