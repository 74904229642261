import {useContext, useState} from "react";
import {GlobalContext} from "../../Context/GlobalContext";

export const ExperienceAdminService = () => {
    const {functionContext, stateContext} = useContext(GlobalContext);
    const {fetchAllExperienceApi, deleteExperienceApi} = functionContext;

    const [fetchExperienceStatus, setFetchExperienceStatus] = useState(false);
    const [experienceList, setExperienceList] = useState(null);
    const [experienceListFilter, setExperienceListFilter] = useState();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [experienceId, setExperienceId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    
    const fetchExperience = () => {
        setIsLoading(true);
        fetchAllExperienceApi((data) => {
            setExperienceList(data);
            setFetchExperienceStatus(true);
        });
        setIsLoading(false);
    }
    
    const onChangeSearch = (e) => {
        let value = e.target.value.toLowerCase();
        if (value === "") {
            setExperienceListFilter(experienceList)
        }
        const filteredList = experienceList.filter((p) => p.position.toLowerCase().includes(value));
        setExperienceListFilter(filteredList);
    }
    
    const handleDelete = () => {
        deleteExperienceApi(experienceId, () => {
            setFetchExperienceStatus(false);
            setOpenModalDelete(false)
        })
    }
    
    const handleOpenDeleteModal = (id) => {
        setExperienceId(id);
        setOpenModalDelete(true);
    }
    
    const handleCloseDeleteModal = (id) => {
        setExperienceId(null);
        setOpenModalDelete(false);
    }
    
    const handleService = {
        fetchExperience, onChangeSearch, handleDelete, handleOpenDeleteModal, handleCloseDeleteModal
    };
    
    const stateService = {
        fetchExperienceStatus, setFetchExperienceStatus, experienceList, setExperienceList,
        experienceListFilter, setExperienceListFilter, openModalDelete, setOpenModalDelete,
        experienceId, setExperienceId, isLoading, setIsLoading
    }
    
    return {
        handleService, stateService
    }
}