import LandingPageWidget from "../widget/LandingPageWidget";
import PortfolioCard2 from "../components/landingPages/Portfolio/PortfolioCard2";
import BreadcrumbsComponent from "../components/BreadcrumbsComponent";
import {Portfolios} from "../utils/data.util";
import {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../Context/GlobalContext";
import SpinnerLoading from "../components/loadings/SpinnerLoading";

const linkList = [
    {
        name: "Home",
        linkUrl: "/"
    },
    {
        name: "Portfolio",
        linkUrl: "/portfolios"
    }
]
const PortfolioList = () => {
    const {functionContext, stateContext} = useContext(GlobalContext);
    const {fetchPortfolioList} = functionContext;
    const {portfolioList} = stateContext;
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        setIsLoading(true);
        fetchPortfolioList()
        setIsLoading(false);
    }, [])
    
    return(
        <LandingPageWidget>
            <div className={'items-center flex flex-col justify-center mx-auto gap-3'}>
                <BreadcrumbsComponent linkList={linkList} />
                {isLoading || !portfolioList && (
                    <SpinnerLoading text={'Loading Data'} />
                )}
                {portfolioList && (
                    <div className={'grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 w-[90%] gap-5'}>
                        {portfolioList?.map((data) => (
                            <PortfolioCard2 portfolio={data} />
                        ))}
                    </div>
                )}
                
            </div>
        </LandingPageWidget>
    )
}
export default PortfolioList;