'use client';

import { Navbar } from 'flowbite-react';
import {Link} from "react-router-dom";

function NavbarLanding() {
    return (
        <Navbar fluid rounded className={'py-4'}>
            <Navbar.Brand href="/">
                {/*<img src="/favicon.svg" className="mr-3 h-6 sm:h-9" alt="Flowbite React Logo" />*/}
                <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">FadhlihApp</span>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
                <Navbar.Link className={'text-lg'}>
                    <Link to={'/'} >Home</Link>
                </Navbar.Link>
                <Navbar.Link href={"#education"} className={'text-lg'}>
                    Educations
                </Navbar.Link>
                <Navbar.Link href="#experience" className={'text-lg'}>Experiences</Navbar.Link>
                <Navbar.Link href="#techstack" className={'text-lg'}>Tech Stack</Navbar.Link>
                <Navbar.Link href="#portfolio" className={'text-lg'}>
                    Portfolios
                </Navbar.Link>
                <Navbar.Link href="#contact" className={'text-lg'}>Contact</Navbar.Link>
            </Navbar.Collapse>
        </Navbar>
    );
}
export default NavbarLanding;