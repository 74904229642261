import React, {useState} from "react";
import downloadIcon from "../../../resources/import download.png";
import SocialMedia from "./SocialMedia";
import {handleDownloadFile} from "../../../utils/HandleDownload";
import TypeIt from "typeit-react";

const Caption = ({fullName, jobName, captionHero, socialMedias, cv}) => {

    const [loading, setLoading] = useState(false);
    const job = "Software Developer";
    const handlePrintPdf = () => {
        setLoading(true);
        handleDownloadFile('file/download', { fileName: cv})
            .then(r => {
                // console.log("success");
            })
            .catch(() => {
                alert('Kesalahan mendownload file');
            })
            .finally(() => {

            })
    }
    
    return(
        <div>
            <section className=" overflow-hidden md:px-6 px-1 sm:px-2 py-8 lg:px-8">
                <div className="mx-auto max-w-2xl lg:max-w-4xl">
                    <figure className="mt-10">
                        <blockquote >
                            <p className="lg:text-3xl text-[#343D68] font-semibold sm:text-2xl sm:leading-9">
                                Hi👋, I’m
                            </p>
                            {(fullName && jobName) && (
                                <div className={'text-5xl text-[#343D68] font-semibold'}>
                                    {/*<TypeIt>{fullName}</TypeIt>*/}
                                    <TypeIt
                                        getBeforeInit={(instance) => {
                                            instance
                                                .type(fullName)
                                                .pause(1000)
                                                .delete(fullName.length)
                                                .pause(1000)
                                                .type(jobName)
                                                .pause(1000).delete(jobName.length).pause(1000).type(fullName)
                                
                                            // Remember to return it!
                                            return instance;
                                        }}
                                    />
                                </div>
                            )}

                            {(!fullName || !jobName) && (
                                <div className={'text-5xl text-[#343D68] font-semibold'}>
                                    {/*<TypeIt>{fullName}</TypeIt>*/}
                                    Fadhlih Girindra Putra
                                </div>
                            )}
                            
                            {/*<p className={'w-full lg:w-4/5 md:w-4/5 mt-5 mb-4 text-slate-500 font-semibold'}>*/}
                            {/*    I'm fresh graduated and passionate full stack web developer */}
                            {/*    with some of app development technologies.  I am fueled by a */}
                            {/*    fervent passion for innovation and a relentless drive to create impactful digital solutions*/}
                            {/*</p>*/}
                            <p className={'w-full lg:w-4/5 md:w-4/5 mt-5 mb-4 text-slate-500 font-semibold'}>
                                {captionHero}
                            </p>
                        </blockquote>
                            {/*<div className="font-semibold text-gray-900">Software Developer</div>*/}
                    </figure>
                </div>
                <div className={'mb-6'}>
                    <button onClick={handlePrintPdf} className={'btn bg-[#0B60B0] text-white rounded-3xl hover:bg-[#0C2D57]'}>Download CV <img width={'34px'} src={downloadIcon} alt={'download'}/></button>
                </div>
                <div className={'ms-2'}>
                    <SocialMedia socialMedias={socialMedias} />
                </div>
                
            </section>
            {/*<h3 className={'text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9'}></h3>*/}
            {/*<h2>Software Developer</h2>*/}
        </div>
    )
}
export default Caption;