import Caption from "./Caption";
import Photo from "./Photo";

const HeroWrapper = ({heroInformation, socialMedias}) => {
    return(
        <div className={'flex flex-col lg:flex-row md:flex-col sm:flex-col items-center justify-between px-8 w-full'}>
            <div>
                <Caption cv={heroInformation?.cv} jobName={heroInformation?.jobName} fullName={heroInformation?.fullName} socialMedias={socialMedias} captionHero={heroInformation?.captionHero} />
            </div>
            <div>
                <Photo photoProfile={heroInformation?.photoUrl} />
            </div>
        </div>
    )
}
export default HeroWrapper;