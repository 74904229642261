import {Button, Modal} from "flowbite-react";
import React, {useState} from "react";
import {axiosConfig} from "../../../configs/axiosConfig";
import {toast} from "react-hot-toast";

export const ModalReplyInbox = ({openModal, onCloseModal, email}) => {
    const [replyForm, setReplyForm] = useState({
        to: email,
        subject: "",
        message: "",
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const {value, name} = e.target;
        setReplyForm({...replyForm, [name]: value})
    }
    
    const handleSubmit = (e) => {
        setIsLoading(true);
        e.preventDefault();
        axiosConfig.post(`inbox/sendAdmin`, replyForm)
            .then(({data}) => {
                toast.success(data.message);
                onCloseModal()
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                console.log(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }
    
    return(
        <Modal show={openModal} size="md" onClose={onCloseModal} popup>
            <Modal.Header />
            <Modal.Body>
                <div className="space-y-6">
                    <h3 className="text-xl text-center font-medium text-gray-900 dark:text-white">Replay Message</h3>

                    <div className={''}>
                        <form className="flex max-w-md flex-col gap-4" onSubmit={handleSubmit}>
                            <div className="col-span-full">
                                <label htmlFor="to" className="block text-sm font-medium leading-6 text-gray-900">
                                    To
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="to"
                                        id="to"
                                        value={replyForm.to}
                                        onChange={handleChange}
                                        autoComplete="to"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="col-span-full">
                                <label htmlFor="subject" className="block text-sm font-medium leading-6 text-gray-900">
                                    Subject
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="subject"
                                        id="subject"
                                        value={replyForm.subject}
                                        onChange={handleChange}
                                        autoComplete="subject"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="message" className="block text-sm font-medium leading-6 text-gray-900">
                                    Message
                                </label>
                                <div className="mt-2">
                    <textarea
                        id="message"
                        name="message"
                        onChange={handleChange}
                        value={replyForm.message}
                        rows={5}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={''}
                    />
                                </div>
                            </div>
                            <Button type="submit" isProcessing={isLoading} >{isLoading ? 'Sending Message' : 'Send Message'}</Button>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}