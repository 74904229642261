import {Carousel} from "flowbite-react";

const CarouselComponent = ({photos}) => {
    return(
        <div className="h-64 sm:h-64 xl:h-80 2xl:h-96 w-full flex justify-center my-8">
            <Carousel className={'w-[70%]'} slideInterval={3000}>
                {photos?.map((data, index) => (
                    <img src={data.photoUrl} className={' w-[100%] h-[100%]'} alt="..." key={index} />
                ))}
            </Carousel>
        </div>
    )
}
export default CarouselComponent