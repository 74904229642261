import buildingIcon from "../../../resources/building.png";
import placeIcon from "../../../resources/place.png";
import {Badge} from "flowbite-react";
import dateIcon from "../../../resources/date-icon.svg";
const ExperienceCard = ({experience}) => {
    return(
        <div className={'flex flex-col w-4/5 hover:bg-slate-200 pt-5'}>
            <div className={'flex flex-col lg:flex-row md:flex-row sm:flex-row justify-between px-2'}>
                <div className={'w-3/4 flex flex-col'}>
                    <h2 className={'text-black text-xl font-semibold'}>{experience.position}</h2>
                    <div className={'flex flex-col lg:flex-row md:flex-row gap-2 lg:gap-8 md:gap-8 my-2'}>
                        <p className={'flex gap-2 font-semibold text-slate-500'}><img src={buildingIcon} className={'h-[24px]'}  alt={'work'}/>{experience.company}</p>
                        <p className={'flex gap-2 font-semibold text-slate-500'}><img src={placeIcon} alt={'place'} width={'24px'}/>{experience.place}</p>
                    </div>
                </div>
                <div className={'w-full lg:w-1/4 md:w-1/4 sm:w-1/4'}>
                    <Badge size={'sm'} color="success" className={'my-2 lg:w-2/6 md:w-2/6 sm:3/6 justify-center'}><strong>{experience.workingType}</strong></Badge>
                    <p className={'flex gap-3 font-semibold text-slate-500'}><img src={dateIcon} width={'18px'} alt={'date'}/> {experience.start} - {experience.end}</p>
                </div>
            </div>
            <p className={'text-slate-700 ml-2 mb-3'}>{experience.description}</p>
            <div className={'w-full h-[1px] bg-slate-900'}></div>
        </div>
    )
}
export default ExperienceCard;