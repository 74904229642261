import {Link, useNavigate} from "react-router-dom";
import {AdminMenus} from "../dummyData/AdminMenu";
import {InboxComponent} from "./admin/InboxComponent";
import {people} from "../pages/Admin/Inbox";
import Cookies from "js-cookie";

export const NavbarAdmin = () => {
    const navigate = useNavigate();
    const handleLogout = () => {
        Cookies.remove('token');
        localStorage.clear();
        navigate('/login')
    }
    return(
        <div className="navbar bg-base-100">
            <div className="navbar-start">
                <div className="dropdown">
                    <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
                    </div>
                    <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                        {AdminMenus.map(({name, linkUrl}, index) => (
                            <li key={index}><Link to={linkUrl}>{name}</Link></li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="navbar-center">
                <Link to={'/admin'} className="btn btn-ghost text-xl">Portfolio Web</Link>
            </div>
            <div className="navbar-end">
                <div className="dropdown dropdown-end">
                    <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
                        <div className="indicator">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" /></svg>
                            <span className="badge badge-sm indicator-item">8</span>
                        </div>
                    </div>
                    <div tabIndex={0} className="mt-3 z-[1] card card-compact dropdown-content w-64 bg-base-100 shadow">
                        <div className="card-body">
                            <div>
                                <ul className="menu w-56 rounded-box">
                                    <li className={'bg-base-200 rounded-box'}><a>Item 1</a></li>
                                    <li><a>Item 2</a></li>
                                    <li className={'bg-base-200 rounded-box'}><a>Item 1</a></li>
                                </ul>
                            </div>
                            <div className="card-actions">
                                <Link to={'/admin/inbox'} className="btn btn-primary btn-block btn-sm">View all</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dropdown dropdown-end">
                    <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
                        <div className="w-10 rounded-full">
                            <img alt="Tailwind CSS Navbar component" src="https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
                        </div>
                    </div>
                    <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                        <li>
                            <Link to={'/admin/account'} className="justify-between">
                                Account
                            </Link>
                        </li>
                        <li>
                            <Link to={'/admin/change-password'} className="justify-between">
                                Change Password
                            </Link>
                        </li>
                        <li>
                            <Link to={'/admin/profile'} className="justify-between">
                                Profile
                            </Link>
                        </li>
                        <li>
                            <Link to={'/admin/inbox'} className="justify-between">
                                Inbox
                            </Link>
                        </li>
                        <li><a onClick={handleLogout}>Logout</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}