const TrackingLine = () => {
    return(
        // <div className="relative">
        //     {/* Bulatan */}
        //     <div className="w-6 h-6 bg-blue-500 rounded-full absolute left-0 transform"></div>
        //
        //     {/* Garis */}
        //     <div className="w-0.5 h-full bg-blue-500 absolute left-3 top-0"></div>
        // </div>
        <div className={'flex flex-col items-center align-center'}>
            {/*<div className={'w-[16px] h-[18px] rounded-full border border-b-emerald-600'}></div>*/}
            <div className={'w-6 h-6 rounded-full border border-blue-500 bg-blue-500'}></div>
            <div className={'w-0.5 h-full bg-blue-500'}></div>
        </div>
    )
}
export default TrackingLine;