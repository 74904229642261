import React, {useEffect} from "react";
import {HeaderPage} from "../../components/admin/HeaderPage";
import {AdminWidget} from "../../widget/AdminWidget";
import {ModalConfirmation} from "../../components/ModalConfirmation";
import {ModalFormSocialMedia} from "../../components/ModalFormSocialMedia";
import {SocialMediaService} from "../Services/SocialMediaService";
import SpinnerLoading from "../../components/loadings/SpinnerLoading";

const breadcrumbs = [
    {
        name: "Home",
        linkUrl: "/admin"
    },
    {
        name: "Master Social Media",
        linkUrl: "/admin/socialMedia"
    }
]
export const SocialMediaAdmin = () => {
    const {handleService, stateService} = SocialMediaService();
    
    const {fetchSocialMedias, onChangeSearch, handleDelete,
        handleCloseDeleteModal, handleCloseFormModal, handleOpenEditForm} = handleService;
    const {socialMediaId, setSocialMediaId, socialMedias, setSocialMedias,
        fetchSocialMediaStatus, setFetchSocialMediaStatus, socialMediasFilter, setSocialMediasFilter,
        openDeleteSocialMedia, setOpenDeleteSocialMedia, openFormSocialMedia, setOpenFormSocialMedia} = stateService

    useEffect(() => {
        fetchSocialMedias();
        setFetchSocialMediaStatus(true);
    }, [fetchSocialMediaStatus, setFetchSocialMediaStatus]);

    useEffect(() => {
        if (socialMedias) {
            setSocialMediasFilter(socialMedias);
            // console.log(portfolioList);
        }
    }, [socialMedias, setSocialMedias]);
    
    return(
        <AdminWidget>
            <ModalConfirmation
                openModal={openDeleteSocialMedia}
                setOpenModal={setOpenDeleteSocialMedia}
                onClickYes={handleDelete}
                onClickCancel={handleCloseDeleteModal}
                title={'Are you sure want delete this social media ?'} />
            
            <ModalFormSocialMedia 
                id={socialMediaId} 
                openModal={openFormSocialMedia} 
                onCloseModal={handleCloseFormModal}
                setFetchStatus={setFetchSocialMediaStatus}
            />

            <HeaderPage linkList={breadcrumbs} tite={'Social Media'} />

            <div className={'flex flex-col lg:flex-row md:flex-row sm:flex-row justify-between'}>
                <button onClick={() => {
                    setSocialMediaId(null);
                    setOpenFormSocialMedia(true)
                }}
                        className={'btn bg-green-700 hover:bg-green-900 text-white'}
                >Add Tech</button>
                <input onChange={onChangeSearch} type="text" placeholder="Search" className="input input-bordered w-full max-w-xs" />
            </div>

            <div className={'bg-white rounded-xl'}>
                {(socialMediasFilter && socialMediasFilter.length === 0) && (
                    <div className="overflow-x-auto">
                        <table className="table table-zebra">
                            {/* head */}
                            <thead>
                            <tr>
                                <th>No</th>
                                <th>Icon</th>
                                <th>Name</th>
                                <th>Link</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                        </table>
                        <div className={'text-center p-3 font-semibold'}>
                            No Data
                        </div>
                    </div>
                )}

                {!socialMediasFilter && (
                    <SpinnerLoading text={'Loading fetch Social Media data'} />
                )}

                {(socialMediasFilter && socialMediasFilter.length > 0) && (
                    <div className="overflow-x-auto">
                        <table className="table table-zebra">
                            {/* head */}
                            <thead>
                            <tr>
                                <th>No</th>
                                <th>Icon</th>
                                <th>Name</th>
                                <th>Link</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {socialMediasFilter.map((data, index) => (
                                <tr>
                                    <th>{index + 1}</th>
                                    <td>
                                        <div className="avatar">
                                            <div className="mask mask-squircle w-12 h-12">
                                                <img src={data.iconUrl} alt="Avatar Tailwind CSS Component" />
                                            </div>
                                        </div>
                                    </td>
                                    <td>{data.name}</td>
                                    <td><a
                                        className={'text-blue-700 hover:text-blue-900'}
                                        href={data.linkUrl} target={"_blank"}>Visit</a></td>
                                    <td>
                                        <div className={'flex gap-1'}>
                                            <button
                                                className={'btn btn-sm bg-green-600 text-white hover:bg-green-800'}
                                                onClick={() => {
                                                    handleOpenEditForm(data.id)
                                                }}
                                            >Edit</button>
                                            <button className={'btn btn-sm bg-red-600 text-white hover:bg-red-800'}
                                                    onClick={() => {
                                                        setSocialMediaId(data.id)
                                                        setOpenDeleteSocialMedia(true)
                                                    }}>Delete</button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            
                            </tbody>
                        </table>
                    </div>
                )}
                
            </div>
        </AdminWidget>
    )
}