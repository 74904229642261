import {useContext, useState} from "react";
import {GlobalContext} from "../../Context/GlobalContext";

export const EducationAdminService = () => {
    const { functionContext, stateContext } = useContext(GlobalContext);
    const {fetchAllEducationApi, deleteEducationApi} = functionContext;
    
    const [fetchEducationStatus, setFetchEducationStatus] = useState(false);
    const [educationList, setEducationList] = useState(null);
    const [educationListFilter, setEducationListFilter] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [educationId, setEducationId] = useState(null);
    const fetchEducations = () => {
        setIsLoading(true);
        fetchAllEducationApi((data) => {
            setEducationList(data);
            setFetchEducationStatus(true);
        });
        setIsLoading(false)
    }

    const onChangeSearch = (e) => {
        let value = e.target.value.toLowerCase();
        if (value === "") {
            setEducationListFilter(educationList)
        }
        const filteredList = educationList.filter((p) => p.subject.toLowerCase().includes(value));
        setEducationListFilter(filteredList);
    }
    
    const handleDelete = () => {
        deleteEducationApi(educationId, () => {
            setFetchEducationStatus(false);
            setOpenModalDelete(false)
        })
    }
    
    const handleOpenDeleteModal = (id) => {
        setEducationId(id);
        setOpenModalDelete(true);
    }

    const handleCloseDeleteModal = (id) => {
        setEducationId(null);
        setOpenModalDelete(false);
    }
    
    const stateService = {
        fetchEducationStatus, setFetchEducationStatus, educationList, setEducationList,
        educationListFilter, setEducationListFilter, isLoading, setIsLoading, openModalDelete, setOpenModalDelete
    }
    
    const handleService = {
        fetchEducations, handleDelete, handleOpenDeleteModal, handleCloseDeleteModal, onChangeSearch
    }
    
    return {
        stateService, handleService
    }
}