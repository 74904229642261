import {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../../Context/GlobalContext";
import {NavigationHandle} from "../../utils/navigationHandle";


export const PortfolioAdminService = () => {
    
    const {functionContext, stateContext} = useContext(GlobalContext);
    const {fetchPortfolioList, deletePortfolioApi, fetchMasterTechList} = functionContext;
    const {portfolioList, setPortfolioList} = stateContext;

    const [portfolioId, setPortfolioId] = useState();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [fetchStatus, setFetchStatus] = useState(false);
    const [portfolioFilter, setPortfolioFilter] = useState();

    const onClickYes = () => {
        // console.log(portfolioId);
        deletePortfolioApi(portfolioId, () => {
            setFetchStatus(false);
            setOpenModalDelete(false);
        });
    }

    const onClickCancel = () => {
        setPortfolioId("-1");
        setOpenModalDelete(false);
    }
    
    const onChangeSearch = (e) => {
        let value = e.target.value.toLowerCase();
        if (value === "") {
            setPortfolioFilter(portfolioList)
        }
        const filteredList = portfolioList.filter((p) => p.title.toLowerCase().includes(value));
        setPortfolioFilter(filteredList);
    }
    
    const stateService = {
        portfolioId, setPortfolioId,
        openModalDelete, setOpenModalDelete,
        fetchStatus, setFetchStatus, portfolioList,
        portfolioFilter, setPortfolioFilter
    }
    
    const handleService = {
        onClickYes, onClickCancel, fetchPortfolioList, onChangeSearch, fetchMasterTechList
    }
    
    return{
        stateService, handleService
    }
}


