import {Card} from "flowbite-react";
import {Link} from "react-router-dom";
import backgroundImg from "../../../resources/background.png";
import {useEffect} from "react";
import Aos from "aos";
import {MaximumWordLength} from "../../../utils/MaximumWordLength";

const PortfolioCard2 = ({portfolio}) => {

    useEffect(() => {
        Aos.init({
            duration: 1000
        });
    }, [])
    
    return(
        <Link data-aos="flip-left" key={portfolio.id} onClick={() => window.scrollTo(0,0)} to={"/portfolio/" + portfolio.id}>
            <Card
                className="max-w-sm hover:bg-slate-200 rounded-2xl h-fit"
                imgAlt="Meaningful alt text for an image that is not purely decorative"
                // imgSrc={portfolio.imgPhoto}
            >
                <img src={portfolio.photoUrl} className={'w-[400px] h-[150px] rounded-xl'} alt={'photo'}/>
                <div>
                    <h2 className="card-title">
                        {MaximumWordLength(portfolio.title, 25)}
                        {/*<div className="badge badge-secondary">NEW</div>*/}
                    </h2>
                    <p>{MaximumWordLength(portfolio.description, 95)}</p>
                    <div className="card-actions justify-start mt-3">
                        {portfolio.portfolioTechs.slice(0, 6).map(({name}) => (
                            <div className="badge badge-outline">{name}</div>
                        ))}
                    </div>
                </div>
            </Card>

            {/*<div className="card w-96 bg-base-100 shadow-xl">*/}
            {/*    <figure><img src={portfolio.imgPhoto} alt="Shoes" className={'h-[128px] w-fit'} /></figure>*/}
            {/*    <div className="card-body">*/}
            {/*        <h2 className="card-title">*/}
            {/*            Shoes!*/}
            {/*            <div className="badge badge-secondary">NEW</div>*/}
            {/*        </h2>*/}
            {/*        <p>If a dog chews shoes whose shoes does he choose?</p>*/}
            {/*        <div className="card-actions justify-end">*/}
            {/*            <div className="badge badge-outline">Fashion</div>*/}
            {/*            <div className="badge badge-outline">Products</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </Link>
    )
}
export default PortfolioCard2;