import React from "react";

const SpinnerLoading = ({text = "Loading", textColor = "black"}) => {
    return(
        <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 9999, display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center", margin: 0 }}>
            <div className={'flex flex-col justify-center items-center'}>
                <span className="loading loading-dots loading-lg"></span>
                <p className={'text-center font-bold'} style={{color: textColor}}>{text ?? 'Loading'}</p>
            </div>
        </div>
    );
};
export default SpinnerLoading;
