import React from "react";
import FooterComponent from "../components/landingPages/FooterComponent";
import {NavbarAdmin} from "../components/NavbarAdmin";

export const AdminWidget = ({children}) => {
    return(
        <React.Fragment>
            <div className={'px-8 sticky top-0 w-full bg-white navbar-wrapper'}>
                <NavbarAdmin />
            </div>
            <div className={'flex flex-col gap-3 content-wrapper px-10 py-5 bg-slate-50'}>
                {children}
            </div>
            <FooterComponent />
            <style jsx>{`
        .navbar-wrapper {
          position: sticky;
          top: 0;
          z-index: 1000; /* Set z-index tinggi untuk navbar */
          background-color: white;
        }

        .content-wrapper {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 14px;
        }
      `}</style>
        </React.Fragment>
    )
}