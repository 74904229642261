import HeroWrapper from "./HeroWrapper";
import React, {useEffect} from "react";
import Aos from "aos";

const Hero = ({ heroInformation, socialMedias }) => {
    
    useEffect(() => {
        Aos.init({
            duration: 1500
        })
    })
    
    return(
        <div data-aos="fade-up" id={"hero"}
             data-aos-anchor-placement="center-bottom" className={'p-1 py-2 lg:py-8 md:py-8 sm:py-4 lg:p-3 sm:p-3 md:p-2 flex items-center w-full justify-between transition duration-700 ease-in-out'}>
            {/*<div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />*/}
            {/*<div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />*/}
            <HeroWrapper heroInformation={heroInformation} socialMedias={socialMedias} />
        </div>
    )
}
export default Hero;
