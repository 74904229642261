
'use client';

import { Footer } from 'flowbite-react';
import {BsGithub, BsInstagram, BsLinkedin, BsYoutube} from 'react-icons/bs';

export default function FooterComponent() {
    return (
        <Footer>
            <div className="w-full px-8 pb-2">
                <Footer.Divider />
                <div className="w-full sm:flex sm:items-center sm:justify-between">
                    <Footer.Copyright className={'font-semibold text-lg'} by="Fadhlih™" year={2024} />
                    <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
                        <Footer.Icon href="#" icon={BsYoutube} />
                        <Footer.Icon href="https://instagram.com/fadhlih17" icon={BsInstagram} />
                        <Footer.Icon href="https://www.linkedin.com/in/fadhlih-girindra-putra" icon={BsLinkedin} />
                        <Footer.Icon href="https://github.com/fadhlihgp" icon={BsGithub} />
                    </div>
                </div>
            </div>
        </Footer>
    );
}
