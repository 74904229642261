import {AdminWidget} from "../../widget/AdminWidget";
import {useNavigate} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../../Context/GlobalContext";
import SpinnerLoading from "../../components/loadings/SpinnerLoading";

export const ProfileForm = () => {
    const navigate = useNavigate();
    const {functionContext} = useContext(GlobalContext);
    const {fetchProfileData, updateProfileApi, uploadPhotoApi, uploadFileApi} = functionContext;
    const [profileDetail, setProfileDetail] = useState({
        fullName: "",
        jobName: "",
        email: "",
        phone: "",
        websiteUrl: "",
        // photoUrl: null,
        // publicPhotoId: null,
        captionHero: "",
        captionContact: "",
        // cv: null
    });
    const [isLoading, setIsLoading] = useState(true);
    const [selectedFile, setSelectedFile] = useState({
        cv: null,
        photo: null
    })
    
    const handleUpdateProfile = (e) => {
        e.preventDefault();
        setIsLoading(true)
        
        if (selectedFile.photo && !selectedFile.cv) {
            const formData = new FormData();
            formData.append('image', selectedFile.photo);
            
            uploadPhotoApi(formData, (data) => {
                updateProfile({
                    ...profileDetail,
                    photoUrl: data.photoUrl, 
                    publicPhotoId: data.publicId
                })
            })
        } else if (selectedFile.cv && !selectedFile.photo) {
            const formData = new FormData();
            formData.append('fileUpload', selectedFile.cv);

            uploadFileApi(formData, (data) => {
                updateProfile({
                    ...profileDetail,
                    cv: data.fileName
                })
            })
        } else if (selectedFile.cv && selectedFile.photo) {
            const formDataImg = new FormData();
            formDataImg.append('image', selectedFile.photo);

            const formDataCv = new FormData();
            formDataCv.append('fileUpload', selectedFile.cv);
            
            uploadPhotoApi(formDataImg, (dataPhoto) => {
                uploadFileApi(formDataCv, (dataCv) => {
                    updateProfile({
                        ...profileDetail,
                        photoUrl: dataPhoto.photoUrl, 
                        publicPhotoId: dataPhoto.publicId,
                        cv: dataCv.fileName
                    })
                })
            })
        } else {
            updateProfile(profileDetail);
        }
    }
    
    const updateProfile = (data) =>{
        updateProfileApi(data, () => {
            navigate('/admin/profile')
        }, () => {
            setIsLoading(false)
        })
    }
    
    const handleChangeInput = (e) => {
        const {value, name} = e.target;
        setProfileDetail({...profileDetail, [name]: value})
    }
    
    const handleChangeFile = (e) => {
        const {name} = e.target;
        setSelectedFile({...selectedFile, [name]: e.target.files[0]})
    }
    
    useEffect(() => {
        fetchProfileData((data) => {
            setProfileDetail({...profileDetail,
                captionContact: data.captionContact,
                captionHero: data.captionHero,
                websiteUrl: data.websiteUrl,
                phone: data.phone,
                jobName: data.jobName,
                email: data.email,
                fullName: data.fullName
            });
            
        }, () => {
            setIsLoading(false)
        })
    }, [])
    
    return(
        <AdminWidget>
            {isLoading && (
                <SpinnerLoading text={'Saving profile data'} />
            )}
            <form onSubmit={handleUpdateProfile}>
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            This information will be displayed publicly so be careful what you share.
                        </p>

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <label htmlFor="fullName" className="block text-sm font-medium leading-6 text-gray-900">
                                    Full Name
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        value={profileDetail.fullName}
                                        onChange={handleChangeInput}
                                        name="fullName"
                                        id="fullName"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2 sm:col-start-1">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Email
                                </label>
                                <div className="mt-2">
                                    <input
                                        value={profileDetail.email}
                                        onChange={handleChangeInput}
                                        type="email"
                                        name="email"
                                        id="email"
                                        autoComplete="address-level2"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="jobName" className="block text-sm font-medium leading-6 text-gray-900">
                                    Job
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="jobName"
                                        id="jobName"
                                        value={profileDetail.jobName}
                                        onChange={handleChangeInput}
                                        autoComplete="address-level1"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2 sm:col-start-1">
                                <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                                    Phone Number
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        onChange={handleChangeInput}
                                        value={profileDetail.phone}
                                        name="phone"
                                        id="phone"
                                        autoComplete="address-level2"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="websiteUrl" className="block text-sm font-medium leading-6 text-gray-900">
                                    Website
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="websiteUrl"
                                        id="websiteUrl"
                                        value={profileDetail.websiteUrl}
                                        onChange={handleChangeInput}
                                        autoComplete="address-level1"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="captionHero" className="block text-sm font-medium leading-6 text-gray-900">
                                    Caption Hero
                                </label>
                                <div className="mt-2">
                                <textarea
                                    id="captionHero"
                                    name="captionHero"
                                    value={profileDetail.captionHero}
                                    onChange={handleChangeInput}
                                    rows={5}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    defaultValue={''}
                                />
                                </div>
                                <p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about yourself.</p>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="captionContact" className="block text-sm font-medium leading-6 text-gray-900">
                                    Caption Contact Me
                                </label>
                                <div className="mt-2">
                                <textarea
                                    id="captionContact"
                                    name="captionContact"
                                    value={profileDetail.captionContact}
                                    onChange={handleChangeInput}
                                    rows={5}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    defaultValue={''}
                                />
                                </div>
                                <p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about yourself.</p>
                            </div>
                            
                            <div className="sm:col-span-full">
                                <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                                    Photo
                                </label>
                                <div className="col-span-full">
                                    <div className={'flex gap-3'}>
                                        <div>
                                            <label className="form-control w-full max-w-xs">
                                                <input name='photo' onChange={handleChangeFile} type="file" className="file-input file-input-bordered w-full max-w-xs" />
                                            </label>
                                        </div>
                                        {/*<div className="">*/}
                                        {/*    <button*/}
                                        {/*        type="button"*/}
                                        {/*        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 btn"*/}
                                        {/*    >*/}
                                        {/*        Upload*/}
                                        {/*    </button>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>

                            <div className="sm:col-span-full">
                                <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                                    CV
                                </label>
                                <div className="col-span-full">
                                    <div className={'flex gap-3'}>
                                        <div>
                                            <label className="form-control w-full max-w-xs">
                                                <input name='cv' onChange={handleChangeFile} type="file" className="file-input file-input-bordered w-full max-w-xs" />
                                            </label>
                                        </div>
                                        {/*<div className="">*/}
                                        {/*    <button*/}
                                        {/*        type="button"*/}
                                        {/*        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 btn"*/}
                                        {/*    >*/}
                                        {/*        Upload*/}
                                        {/*    </button>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            
                        </div>
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-3">
                    <button onClick={() => navigate('/admin/profile')} type="button" className="text-sm font-semibold leading-6 text-gray-900 bg-white btn btn-sm">
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn-sm btn bg-blue-600 hover:bg-blue-800 text-white"
                    >
                        Save
                    </button>
                </div>
            </form>
        </AdminWidget>
    )
}