import {Fragment} from "react";
import TitleProject from "./TitleProject";

const ProjectDescription = ({description, linkProject}) => {
    return(
        <Fragment>
            <TitleProject text={"Project Description"} />
            <p style={{whiteSpace: "pre-line"}} className={'text-justify'}>{description}</p>
            <a href={linkProject ? linkProject : '#'} target="_blank" className={`btn btn-sm bg-[#205295] text-white hover:bg-[#0B2447] w-[150px] ${!linkProject && 'cursor-not-allowed opacity-50'}`} disabled={!linkProject}>Project Link</a>

        </Fragment>
    )
}
export default ProjectDescription;