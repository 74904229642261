import {Button, Checkbox, Label, Modal, TextInput} from "flowbite-react";
import React, {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../../Context/GlobalContext";
import SpinnerLoading from "../loadings/SpinnerLoading";

export const ModalFormSkill = ({openModal, onCloseModal, id, setFetchStatus}) => {
    const title = id === null ? 'Add Skill' : 'Edit skill';
    const {functionContext, stateContext} = useContext(GlobalContext);
    const {fetchAllTechTypeApi, fetchMasterTechListApi, fetchSkillByIdApi,
        createSkillApi, updateSkillApi
    } = functionContext;

    const {masterTechList, setMasterTechList, techTypeList, setTechTypeList} = stateContext;
    const [skillForm, setSkillForm] = useState({
        masterTechId: "",
        techTypeId: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        fetchMasterTechListApi();
        fetchAllTechTypeApi();
        if (id) {
            fetchSkillByIdApi(id, (data) => {
                setSkillForm({...skillForm,
                    masterTechId: data.masterTechId,
                    techTypeId: data.techTypeId
                })
            })
        }
    }, [id])
    
    const onChangeSelect = (e) => {
        const {name, value} = e.target;
        setSkillForm({...skillForm, [name]: value})
    }
    
    const reset = () => {
        setSkillForm({...skillForm, techTypeId: "", masterTechId: ""});
        onCloseModal()
    }
    const submit = () => {
        setIsLoading(true);
        if (!id) {
            createSkillApi(skillForm, () => {
                setFetchStatus(false);
                reset();
            })
        } else {
            updateSkillApi(id, skillForm, () => {
                setFetchStatus(false);
                reset();
            })
        }
        setIsLoading(false);
    }
    return(
        <Modal show={openModal} size="md" onClose={reset} popup>
            {isLoading && (
                <SpinnerLoading text={'Saving skill data'} />
            )}
            <Modal.Header />
            <Modal.Body>
                <div className="space-y-6">
                    <h3 className="text-xl text-center font-medium text-gray-900 dark:text-white">{title}</h3>

                    <div className="col-span-full">
                        <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                            Tech Stack
                        </label>
                        <div className="mt-2">
                            <select onChange={onChangeSelect} name={'masterTechId'} value={skillForm.masterTechId} defaultValue={""} className="select select-primary w-full max-w-xs">
                                <option value={""} disabled selected>Choose Tech Stack</option>
                                {masterTechList?.map((data, index) => (
                                    <option value={data.id} key={index}>{data.name}</option>
                                ))}
                                {/*<option>Game of Thrones</option>*/}
                                {/*<option>Lost</option>*/}
                                {/*<option>Breaking Bad</option>*/}
                                {/*<option>Walking Dead</option>*/}
                            </select>
                        </div>
                    </div>

                    <div className="col-span-full">
                        <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                            Type
                        </label>
                        <div className="mt-2">
                            <select onChange={onChangeSelect} value={skillForm.techTypeId} name={'techTypeId'} className="select select-primary w-full max-w-xs">
                                <option value={""} disabled selected>Choose Type</option>
                                {techTypeList?.map((data, index) => (
                                    <option key={index} value={data.id}>{data.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="w-full flex gap-3">
                        <Button onClick={submit}>Save</Button>
                        <Button color={'gray'} onClick={reset}>Cancel</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}