import {Link} from "react-router-dom";

const BreadcrumbsComponent = ({linkList}) => {
    const lengthList = linkList.length;
      return(
          <div className="text-md breadcrumbs font-semibold text-lg">
              <ul>
                  {linkList.map(({name, linkUrl}, index) => {
                      if (lengthList - index !== 1) {
                          return <li key={index}><Link className={'hover:text-blue-600'} to={linkUrl}>{name}</Link></li>
                      } else {
                          return <li key={index}>{name}</li>
                      }
                  })}
                  {/*<li><a>Home</a></li>*/}
                  {/*<li><a>Documents</a></li>*/}
                  {/*<li>Add Document</li>*/}
              </ul>
          </div>
      )
}
export default BreadcrumbsComponent;