import {useContext, useState} from "react";
import {GlobalContext} from "../../Context/GlobalContext";

export const TechStackAdminService = () => {
    const {functionContext} = useContext(GlobalContext);
    const {fetchAllSkillApi, deleteSkillApi} = functionContext;

    // const {skills, setSkills} = useState(null);
    const [skills, setSkills] = useState(null);
    const [skillId, setSkillId] = useState(null);
    const [fetchSkillStatus, setFetchSkillStatus] = useState(false);
    const [skillsFilter, setSkillsFilter] = useState(null);
    const [openDeleteSkill, setOpenDeleteSkill] = useState(false);
    const [openFormSkill, setOpenFormSkill] = useState(false);

    const fetchSkills = () => {
        fetchAllSkillApi((data) => {
            setSkills(data);
        });
    }

    const onChangeSearch = (e) => {
        let value = e.target.value.toLowerCase();
        if (value === "") {
            setSkillsFilter(skills)
        }
        const filteredList = skills.filter((p) => p.name.toLowerCase().includes(value));
        setSkillsFilter(filteredList);
    }

    const handleDelete = () => {
        deleteSkillApi(skillId, () => {
            setFetchSkillStatus(false);
            setOpenDeleteSkill(false);
        });
    }

    const handleButtonAdd = () => {
        setSkillId(null);
        setOpenFormSkill(true);
    }

    const handleCloseDeleteModal = () => {
        setSkillId(null);
        setOpenDeleteSkill(false);
    }

    const handleCloseFormModal = () => {
        setSkillId(null);
        setOpenFormSkill(false);
    }

    // Form
    const handleOpenEditForm = (id) => {
        setSkillId(id);
        setOpenFormSkill(true)
    }

    const stateService = {
        skills, setSkills, skillId, setSkillId,
        fetchSkillStatus, setFetchSkillStatus, skillsFilter, setSkillsFilter,
        openDeleteSkill, setOpenDeleteSkill, openFormSkill, setOpenFormSkill
    }

    const handleService = {
        fetchSkills, onChangeSearch, handleDelete, handleButtonAdd,
        handleCloseDeleteModal, handleCloseFormModal, handleOpenEditForm
    }

    return {
        stateService, handleService
    }
}