
'use client';

import { Button } from 'flowbite-react';
import {useState} from "react";
import {axiosConfig} from "../../../configs/axiosConfig";
import {toast} from "react-hot-toast";

export default function FormSendEmail() {
    const [inboxForm, setInboxForm] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const {value, name} = e.target;
        setInboxForm({...inboxForm, [name]: value})
    }

    const handleSubmit = (e) => {
        setIsLoading(true);
        e.preventDefault();
        axiosConfig.post(`inbox`, inboxForm)
            .then(({data}) => {
                toast.success(data.message);
                setInboxForm({
                    name: "",
                    email: "",
                    subject: "",
                    message: "",
                })
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                console.log(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }
    
    return (
        <div className={''}>
            <form className="flex max-w-md flex-col gap-4" onSubmit={handleSubmit}>
                <div className="col-span-full">
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                        Your Name
                    </label>
                    <div className="mt-2">
                        <input
                            type="text"
                            value={inboxForm.name}
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            name="name"
                            id="name"
                            autoComplete="name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className="col-span-full">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Your Email
                    </label>
                    <div className="mt-2">
                        <input
                            type="email"
                            value={inboxForm.email}
                            onSubmit={handleSubmit}
                            onChange={handleChange}
                            name="email"
                            id="email"
                            autoComplete="email"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className="col-span-full">
                    <label htmlFor="subject" className="block text-sm font-medium leading-6 text-gray-900">
                        Subject
                    </label>
                    <div className="mt-2">
                        <input
                            type="text"
                            value={inboxForm.subject}
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            name="subject"
                            id="subject"
                            autoComplete="subject"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div>
                    <label htmlFor="message" className="block text-sm font-medium leading-6 text-gray-900">
                        Message
                    </label>
                    <div className="mt-2">
                    <textarea
                        id="message"
                        name="message"
                        value={inboxForm.message}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        rows={5}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={''}
                    />
                    </div>
                </div>
                <Button isProcessing={isLoading} disabled={isLoading} type="submit">{isLoading ? 'Sending Message ...' : 'Send Message'}</Button>
            </form>
        </div>
    );
}
